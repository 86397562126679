
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SmsNotifications from '@/components/SmsNotifications.vue';
import AutoSettings from '@/components/AutoSettings.vue';
import L from 'leaflet';
import MapCommon from '../utils/MapCommon';
import PigRunCommon from '../utils/PigRunCommon';
import { PigRun } from '../models/PigRun';
import { Passage } from '../models/Passage';
import { MarkerPassage } from '../models/MarkerPassage';
import { Device } from '../models/Device';
import { AgmMarker } from '../models/AgmMarker';
import { UserNotification } from '@/models/UserNotification';
import along from '@turf/along';
import * as turf from '@turf/turf';
import * as turfHelpers from '@turf/helpers';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
import PigRunService from '@/services/PigRunService';
import { DeleteAllPigRunToolsForPigRunRequest } from '@/models/DeleteAllPigRunToolsForPigRunRequest';
import DisplayMLResult, {
  MachineLearningResultColors,
} from '@/utils/DisplayMLResult';
import { EventBus } from '@/utils/EventBus';
import { IPassageVerified } from '@/models/EventBus/PassageVerified';

const logOptions = {
  app: 'PigviewDev',
  level: 'debug',
};

// @ts-ignore
//const logger = logdna.createLogger('4553104e18f4ccdb6499a94873b9b9f1', logOptions);

@Component({
  components: {
    SmsNotifications,
    AutoSettings,
  },
})
export default class PigProgressMarkers extends Vue {
  @Prop(Number) public readonly position: number | undefined;
  @Prop(PigRun) public pigRun: PigRun | undefined;
  public isOnline = true;
  public autoLinkRadii: L.Circle[] = [];
  public tmpMarkerEdits: AgmMarker[] = [];
  public addAgmsDialog = false;
  public tmpSelectedAgms: number[] = [];
  public agmUidOptions: any = [];
  public cancelConfirmDialog = false;
  public addPassageDialog = false;
  public passagesDialog = false;
  public showMissedPassages = false;
  public minimizeDialog = false;
  public addNoteDialog = false;
  public deleteConfirmDialog = false;
  public editConfirmDialog = false;
  public changeNotificationsDialog = false;
  public selectedMarkerName = '';
  public selectedMarkerPassage: MarkerPassage | null = null;
  public noteText = '';
  public assignUidDialog = false;
  public selectedUid = '';
  public passageMarker: string | null = null;
  public ManualPsg_ErrorMsg = '';
  public VerifyPsg_ErrorMsg = '';
  public passageTimestamp = '';
  public applicableAlgorithmIds: number[] = [];
  public visibleColumns = [
    'passageTimeStamp',
    'uid',
    'graph-data',
    'verify-data',
    'machine-learning-data',
  ];
  public passageColumns = [
    {
      name: 'passageTimeStamp',
      label: 'Timestamp',
      field: 'passageTimeStamp',
      sortable: true,
      required: true,
      align: 'center',
    },
    {
      name: 'markerName',
      label: 'Marker Name',
      field: 'markerName',
      sortable: true,
      required: true,
      align: 'center',
    },
    {
      name: 'uid',
      label: 'UID',
      field: 'uid',
      sortable: true,
      align: 'center',
    },
    {
      name: 'detectionCount',
      label: 'Detection Count',
      field: 'detectionCount',
      sortable: true,
      align: 'center',
    },
    { name: 'graph-data', field: 'passageId', label: 'Data', align: 'center' }, // changed to graph btn in slot
    {
      name: 'verify-data',
      field: 'passageId',
      label: 'Verified',
      align: 'center',
    }, // changed to check btn in slot
    {
      name: 'machine-learning-data',
      label: 'ML Verified',
      align: 'center',
    },
  ];
  public missedPassageColumns = [
    {
      name: 'passageTimeStamp',
      label: 'Timestamp',
      field: 'passageTimeStamp',
      sortable: true,
      required: true,
      align: 'left',
    },
    { name: 'uid', label: 'UID', field: 'uid', sortable: true },
    {
      name: 'detectionCount',
      label: 'Detection Count',
      field: 'detectionCount',
      sortable: true,
    },
    { name: 'graph-data', field: 'passageId', label: 'Data' }, // changed to graph btn in slot
    { name: 'add-data', field: 'passageId', label: 'Add' }, // changed to add btn in slot
  ];
  public passagePagination = {
    sortBy: 'passageTimeStamp',
    descending: true,
    rowsPerPage: -1,
  };
  public passageTimeStamps: string[] = [];

  public direction: 'next' | 'previous' = 'next';
  public showRunInfo = true;
  public furthestVerifiedPosition = -1;
  public timerId = -1;
  public idleTimerId = -1;
  public refershFrequency = 1000; // don't change this unless we use a different interval for elapsedSeconds
  public refershFrequency2 = 1000; // don't change this unless we use a different interval for elapsedSeconds
  public refershFrequency3 = 3000;
  public nextState = 'TRACK';
  public nextMarkerName: string | null = null;
  public previousMarkerName: string | null = null;
  public etaNext = -1;
  public etaComplete = -1;
  public elapsedSeconds = 1;
  public idleSeconds = 1;
  public onlineTimeout: any = null;
  public distanceTotal = 0;
  public distanceFromStart = 0;

  public complete_time = '';

  public pigRunLayers: L.LayerGroup | null = null;
  public launchMarker: L.Marker | null = null;
  public recieveMarker: L.Marker | null = null;
  public pigMarker: L.Marker | null = null;
  public offlineAgms: { [uid: number]: L.Marker } = {}; // uid: L.marker
  public pigRunPath: L.Polyline | null = null;
  public agmMapMarkers: L.Marker[] = [];
  public tempMarker: L.Marker | null = null;
  public tempMarker_lat?: number = -1;
  public tempMarker_lon?: number = -1;
  public tempMarker_Speed?: number = -1;
  public next_index?: number = -1;
  public next_index2?: number = -1;

  public userNotifications: UserNotification[] = [];
  public tmpUserNotifications: UserNotification[] = [];

  ////////
  public endPigRunDialog = false;

  public newSpeed?: number | null = null;
  public SpeedDesc = '';
  public changeSpeedDialog = false;
  public SpeedUnits = 'm/s';
  public EditPigDialog = false;

  public showErrorMsg = false;
  public showErrorMsg2 = false;

  public EditManual_ErrorMsg = false;
  public ShowVerifyPsg_ErrorMsg = false;

  public isRunPaused = false;

  public ReceiveErrorMsg = '';
  public checkstate = false;
  public ValidateReceiveTime(ReceiveTimestamp: any) {
    // Check to see if Receive Time is before Launch Time
    if (this.CheckifBeforeLaunch(ReceiveTimestamp + ' GMT') == true) {
      // Error
      console.log('PigProgressMarker: Pig Receive Time is before Launch Time');
      this.ReceiveErrorMsg = 'Receive Time is before Launch Time';
      return 0;
    }
    // TDO check if receive time before a verified passage time
    var recTime = new Date(ReceiveTimestamp + ' GMT');

    if (this.pigRun && this.pigRun.passages) {
      this.checkstate = false;
      for (var i = 0; i < this.pigRun.passages.length; i++) {
        if (this.pigRun.passages[i].isVerified == true) {
          var PsgTime = new Date(
            this.pigRun.passages[i].passageTimeStamp + ' GMT'
          );
          var diff = recTime.getTime() - PsgTime.getTime();

          if (diff <= 0) {
            // if recTime is less than PsgTime
            this.checkstate = true;

            console.log(
              'PigProgressMarker: Pig Receive Time is before Verified Psg Time'
            );

            this.ReceiveErrorMsg =
              'Time is before Psg at Marker ' +
              this.pigRun.passages[i].markerName;
            return 0; /// An error case exist
          }
        }
      }
    }
    this.ReceiveErrorMsg = '';
    return -1;
  }

  public handleNextMarker(direction: 'previous' | 'next') {
    console.log('PigProgressMarker: handleNextMarker direction', direction);

    var markers = this.pigRun?.markers || [];

    const currentIndex = markers.findIndex(
      (marker: AgmMarker) => marker.name === this.selectedMarkerName
    );

    if (currentIndex === -1) {
      console.log('PigProgressMarker: handleNextMarker - marker not found');
      return;
    }

    const increment = direction === 'next' ? 1 : -1;

    const nextIndex =
      (currentIndex + increment + markers.length) % markers.length;

    this.selectedMarkerName = markers[nextIndex].name;
    this.$store.dispatch('setSelectedMarker', markers[nextIndex]);
  }

  public getMLResultColor(data: any): MachineLearningResultColors {
    return DisplayMLResult(data).color;
  }

  public getMLResultConfidence(data: any): string {
    return ' ' + DisplayMLResult(data).confidence + '%';
  }

  public getMLIcon(color: MachineLearningResultColors): string {
    if (color.includes('red')) {
      return 'block';
    } else if (color.includes('green')) {
      return 'check_circle_outline';
    } else {
      return 'help';
    }
  }

  public onSpeedEdit() {
    console.log('PigProgressMarkers: onSpeedEdit()');
    this.changeSpeedDialog = true;
  }
  public onSpeedUnitsSelect(input: number) {
    if (input == 1) {
      this.SpeedUnits = 'm/s';
    } else if (input == 2) {
      this.SpeedUnits = 'mph';
    }
  }
  public onSaveNewSpeed() {
    if (this.pigRun) {
      if (this.pigRun.calculatedSpeed && this.pigRun.calculatedSpeed > 0) {
        if (this.newSpeed != null) {
          if (this.SpeedUnits == 'm/s') {
            this.pigRun.calculatedSpeed = this.newSpeed;
          } else {
            this.pigRun.calculatedSpeed = this.newSpeed * 0.44704;
          }
        }
      } else {
        if (this.pigRun.pigPath.expectedSpeed) {
          if (this.newSpeed != null) {
            if (this.SpeedUnits == 'm/s') {
              this.pigRun.pigPath.expectedSpeed = this.newSpeed;
            } else {
              this.pigRun.pigPath.expectedSpeed = this.newSpeed * 0.44704;
            }
          }
        }
      }

      this.newSpeed = null;
    }
  }

  public onPigRunEndPressed() {
    console.log('PigProgressMarkers: endPigRunDialog()');
    this.endPigRunDialog = true;
    this.ReceiveTimestamp = dayjs.default().utc().format('YYYY-MM-DD HH:mm:ss');
  }

  ///////

  public UserRole = '';
  public getuser() {
    //use this method to get user role. So that certain things get displayed/hidden based on role.
    this.UserRole = this.$store.state.user.role;
    //console.log(this.$store.state.user)
    return this.UserRole;
  }

  public unsubscribe = this.$store.subscribeAction((action, state) => {
    if (action.type === 'refreshPigRun') {
      this.onRefreshAction(action.payload);
    }
    if (action.type === 'changeMarkerDescription') {
      this.onChangeMarkerDescription(action.payload);
    }
    if (action.type === 'changeMarkerState') {
      this.onChangeMarkerState(action.payload);
    }
    if (action.type === 'addManualMarkerPassage') {
      this.onAddPassage(action.payload[0], action.payload[1]);
    }
    if (action.type === 'addMarkerNote') {
      this.onAddMarkerNote(action.payload);
    }
    if (action.type === 'assignMarkerUid') {
      this.onAssignMarkerUid(action.payload);
    }
    if (action.type === 'unlinkMarkerUid') {
      this.onUnlinkMarkerUid(action.payload);
    }
    if (action.type === 'calculateMarkerEta') {
      this.calculateMarkerEta(action.payload);
    }
    if (action.type === 'verifyMarkerPassage') {
      this.onViewPassages(action.payload);
    }
    if (action.type === 'verifyPassageComplete') {
      this.onVerifyFromHistory(action.payload);
    }
    if (action.type === 'passageRecieved') {
      //this.onPassageRecieved(action.payload);
    }
    if (action.type === 'updatedTimeStampData') {
      this.onUpdatedTimeStamp(action.payload);
    }
    if (action.type === 'updatePigLoc') {
      this.onupdateLocAction(action.payload);
    }
    if (action.type === 'updatePigLocV2') {
      this.onupdateLocActionV2(action.payload);
    }
    if (action.type === 'pigProgressMaximizeRequest') {
      this.RqstToMax(action.payload);
    }
    if (action.type === 'pigProgressMinimizeRequest') {
      this.RqstToMin(action.payload);
    }
  });

  public RqstToMax(Id: number) {
    console.log('PigProgressMarkers.vue:Rqst to max ID:');

    if (this.pigRun) {
      console.log(this.pigRun.runId);

      if (Id != this.pigRun.runId) {
        // this.onZoomToPig();
        this.minimizeDialog = true;

        console.log('PigProgressMarkers.vue:RqstToMax: removing layers');
        // this.pigRunLayers?.remove();
      } else {
        // this.onZoomToPig();
        console.log('PigProgressMarkers.vue:RqstToMax:Pig Run ID Not same:');
        this.minimizeDialog = false;
      }
    }
  }

  public RqstToMin(Id: number) {
    console.log('PigProgressMarkers.vue:Rqst to min ID:');
    if (this.pigRun) {
      console.log(this.pigRun.runId);
      if (Id == this.pigRun.runId) {
        this.minimizeDialog = true;
        console.log('PigProgressMarkers.vue:RqstToMin: removing layers');
        //this.pigRunLayers?.remove();
      } else {
        console.log('PigProgressMarkers.vue:RqstToMin:Pig Run ID Not same:');
      }
    } else {
      console.log('PigProgressMarkers.vue:Rqst to min UNDEFINED:');
    }
  }

  @Watch('isOnline', { immediate: false, deep: true })
  public isOnlineChanged(value: boolean, oldValue: boolean) {
    //logger.log(`${this.userInfo} isOnline changed => ${value}`);
    //console.log(`isOnline changed => ${value}`);
  }

  @Watch('pigRun', { immediate: false, deep: true })
  public pigRunChanged(value: PigRun, oldValue: PigRun) {
    console.log('PigProgressMarkers.vue. pigRunChanged. Enter method.');

    let Psgs: MarkerPassage;
    let oldPsgs: MarkerPassage;
    let MarkersChange = false;

    if (oldValue.markers != undefined) {
      console.log(
        'PigProgressMarkers.vue. pigRunChanged. Marker[0].state (oldValue) = [' +
          oldValue.markers[0].state +
          '].'
      );
    }

    if (value.markers != undefined) {
      console.log(
        'PigProgressMarkers.vue. pigRunChanged. Marker[0].state (value) = [' +
          value.markers[0].state +
          '].'
      );
    }

    if (!this.map.hasLayer(this.pigRunLayers)) {
      console.log('Map Does Not have this layer active exit!');

      return;
    } else {
      console.log('Map Has this layer active!');
    }

    if (value !== oldValue) {
      console.log(
        'PigProgressMarkers.vue: PigRunChanged: !!!!!!!!!!!!!!!!!!PIG RUN UPDATING!!!!!!!!!!!!!!'
      );
      // ths handles the case where a pig run was updated by an outside force, probably another user
      console.log('PigRun changed');
      // instead of updating all markers remove then add with updated info
      // this handles both uid linking and updated notes
      console.log('remove agmMapMarkers');
      if (oldValue && oldValue.markers) {
        const oldLinkedIds = oldValue.markers.map(
          (marker: AgmMarker) => marker.linkedUid
        );
        for (const uid of oldLinkedIds) {
          if (uid) {
            const device = this.devices.find((d: Device) => d.uid === uid);
            if (device && device.lat !== 0 && device.lon !== 0) {
              this.map.eachLayer((layer: any) => {
                if (layer.hasOwnProperty('_leaflet_id')) {
                  if (this.leafletLayers[layer._leaflet_id] === device.uid) {
                    if (device.isIridium) {
                      if (device.isConnected) {
                        layer.setIcon(MapCommon.agmIconOnlineIridium);
                      } else {
                        layer.setIcon(MapCommon.agmIconOfflineIridium);
                      }
                    } else {
                      if (device.isConnected) {
                        layer.setIcon(MapCommon.agmIconOnline);
                      } else {
                        layer.setIcon(MapCommon.agmIconOffline);
                      }
                    }
                  }
                }
              });
            }
          }
        }
      }

      this.agmMapMarkers.forEach((Marker: L.Marker) => {
        Marker.removeFrom(this.map);
      });

      // We also need to remove the pigrun generated line and redraw it
      if (this.pigRun != null && this.pigRun != undefined) {
        this.pigRunLayers?.clearLayers();

        //this.pigRunLayers?.removeFrom(this.map);
        this.layerControl.removeLayer(this.pigRunLayers);

        this.setupPigRun_V2();
      }
    }

    if (oldValue.markers != undefined) {
      console.log(
        'PigProgressMarkers.vue. pigRunChanged. Marker[0].state (oldValue prior to exit) = [' +
          oldValue.markers[0].state +
          '].'
      );
    }

    if (value.markers != undefined) {
      console.log(
        'PigProgressMarkers.vue. pigRunChanged. Marker[0].state (value prior to exit) = [' +
          value.markers[0].state +
          '].'
      );
    }
  }

  get userInfo() {
    return `${this.$store.state.user.name} ${navigator.userAgent}`;
  }

  get activePigRuns() {
    return this.$store.state.pigRun.activePigRuns;
  }

  get map() {
    return this.$store.state.map;
  }

  get markerLayers() {
    // look for markerlayers of specefic runid
    if (this.pigRun) {
      for (var i = 0; i < this.$store.state.ActiveRuns.length; i++) {
        if (this.pigRun.runId == this.$store.state.ActiveRuns[i].pigrunid) {
          // console.log('PigProgressMarkers. MITCH - IN HERE.' + this.$store.state.ActiveRuns[i].pigrunid);

          return this.$store.state.ActiveRuns[i].localmarkerLayers;
        }
      }
    }
    //return this.$store.state.markerLayers;
  }

  get pigRunMarkerNames() {
    if (this.pigRun && this.pigRun.markers) {
      return this.pigRun.markers.map((marker: AgmMarker) => marker.name);
    }
    return [];
  }

  get calculatedSpeedFormatted() {
    if (this.pigRun) {
      if (this.pigRun.calculatedSpeed && this.pigRun.calculatedSpeed > 0) {
        return (
          Math.round(
            PigRunCommon.metersToMiles(this.pigRun.calculatedSpeed) * 100
          ) / 100
        );
      }
    }
    return '';
  }

  get expectedSpeedFormatted() {
    if (this.pigRun) {
      if (this.pigRun.pigPath.expectedSpeed) {
        return (
          Math.round(
            PigRunCommon.metersToMiles(this.pigRun.pigPath.expectedSpeed) * 100
          ) / 100
        );
      }
    }
    return '';
  }

  get etaNextFormated() {
    if (this.etaNext === -1) {
      return 'n/a';
    } else if (this.etaNext <= 0) {
      return 'now';
    }
    return this.formatEta(this.etaNext);
  }

  get etaCompleteFormated() {
    if (this.etaComplete === -1) {
      if (this.isRunPaused) {
        return 'n/a (Paused)';
      }
      return 'n/a';
    } else if (this.etaComplete <= 0) {
      if (this.isRunPaused) {
        return this.complete_time + ' (now - Paused)';
      }

      return this.complete_time + ' (now)';
      //return 'now';
    }

    if (this.isRunPaused) {
      return this.formatEta(this.etaComplete) + ' (Paused)';
    }
    return this.formatEta(this.etaComplete);
  }

  get positionStyle() {
    if (this.minimizeDialog) {
      if (this.position) {
        //return 'width: 55px; top: ' + this.position * 120 + 'px';
        return 'width: 55px';
      }
      return 'width: 55px; ';
    } else {
      if (this.position) {
        var is_mobile = /mobile|android/i.test(navigator.userAgent);
        if (is_mobile) {
          //return 'width: 100px; top: ' + this.position * 50 + 'px';
          return 'width: 100px';
        }
        //return 'width: 250; top: ' + this.position * 105 + 'px';
        return 'width: 250';
      }
      return 'width: 250px; ';
    }
  }

  get layerControl() {
    return this.$store.state.layerControl;
  }

  get devices() {
    return this.$store.state.devices;
  }

  get linkedUids() {
    if (this.pigRun && this.pigRun.markers) {
      return this.pigRun.markers.map((marker: AgmMarker) => marker.linkedUid);
    }
    return [];
  }

  get availableUids() {
    if (this.pigRun && this.pigRun.markers) {
      const availableUids = [];
      const linkedMarkers = [];
      for (const marker of this.pigRun.markers) {
        if (marker.linkedUid) {
          linkedMarkers.push(marker.linkedUid);
        }
      }
      for (const agm of this.pigRun.pigPath.agms) {
        if (linkedMarkers.indexOf(agm) === -1) {
          availableUids.push(agm);
        }
      }
      return availableUids;
    }
    return this.$store.getters.deviceUidNumbers;
  }

  get leafletLayers() {
    return this.$store.state.leafletLayers;
  }

  get ignoreNextPigRunUpdate() {
    return this.$store.state.pigRun.ignoreNextPigRunUpdate;
  }

  get filteredPassages(): MarkerPassage[] {
    if (this.pigRun && this.pigRun.passages && this.selectedMarkerName !== '') {
      // Retrieving marker marker passages, filtering for applicable COnfidences, then returning
      let markerPassageArray = this.pigRun.passages.filter(
        (passage: MarkerPassage) =>
          passage.markerName === this.selectedMarkerName
      );

      if (
        this.$store.state.machineLearningPassageResultList == undefined ||
        this.$store.state.machineLearningPassageResultList.length == 0
      ) {
        return markerPassageArray;
      }

      let passagesCopy: MarkerPassage[] = [];
      for (let i = 0; i < markerPassageArray.length; i++) {
        passagesCopy[i] = markerPassageArray[i];
      }

      // For all of the machine learning lists inside our marker passage array.
      for (let i = 0; i < markerPassageArray.length; i++) {
        for (let i = 0; i < markerPassageArray.length; i++) {
          if (
            markerPassageArray[i].passageId ==
            this.$store.state.machineLearningPassageResultList[i].passageId
          ) {
            passagesCopy[i].elfMachineLearningResultList =
              this.$store.state.machineLearningPassageResultList[
                i
              ].elfMachineLearningResultList;
            passagesCopy[i].geoMachineLearningResultList =
              this.$store.state.machineLearningPassageResultList[
                i
              ].geoMachineLearningResultList;
            passagesCopy[i].magMachineLearningResultList =
              this.$store.state.machineLearningPassageResultList[
                i
              ].magMachineLearningResultList;
          }
        }
      }

      return passagesCopy;
    }
    return [];
  }

  get autoLinkDistance() {
    return this.$store.state.pigRun.autoLinkDistance;
  }

  get autoVerifyMinutes() {
    return this.$store.state.pigRun.autoVerifyMinutes;
  }

  get missedPassages() {
    return this.$store.state.pigRun.missedPassages;
  }

  get markerInfo() {
    if (this.pigRun && this.pigRun.pigPath) {
      return this.pigRun.pigPath.getMarkerInfo();
    }
  }

  public onUnlinkMarkerUid(payload: any) {
    if (this.pigRun && this.pigRun.markers) {
      const selectedMarker = this.pigRun.markers.find((marker: AgmMarker) => {
        return marker.name === payload;
      });
      if (selectedMarker) {
        this.onUnlinkUid(selectedMarker.name);
      }
    }
  }

  public onAssignMarkerUid(payload: any) {
    if (this.pigRun && this.pigRun.markers) {
      const selectedMarker = this.pigRun.markers.find((marker: AgmMarker) => {
        return marker.name === payload;
      });
      if (selectedMarker) {
        this.onAssignUidDialog(selectedMarker.name, selectedMarker.linkedUid);
      }
    }
  }

  public onAddMarkerNote(payload: any) {
    if (this.pigRun && this.pigRun.markers) {
      const selectedMarker = this.pigRun.markers.find((marker: AgmMarker) => {
        return marker.name === payload;
      });
      if (selectedMarker) {
        this.onShowNoteDialog(selectedMarker.name, selectedMarker.note);
      }
    }
  }

  public onUpdatedTimeStamp(payload: any) {
    for (const passageId of payload.passageIds) {
      // search for any 3 of mag/elf/geo id in passages, apply first match and then break
      const result = this.pigRun!.passages!.find(
        (item: any) => item.passageId === passageId
      );
      if (result) {
        result.passageTimeStamp = payload.updatedTimeStamp.slice(0, -3);
        //logger.log(`${this.userInfo} updatedTimeStampData=>updatePigRun`);
        this.updatePigRun(false);
        break;
      }
    }
  }

  public formatEta(seconds: number) {
    const totalHours = Math.floor(seconds / (60 * 60));
    const totalSeconds = seconds - totalHours * 60 * 60;
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalMinutesString = totalMinutes.toString().padStart(2, '0');
    const totalSecondsString = Math.floor(totalSeconds - totalMinutes * 60)
      .toString()
      .padStart(2, '0');

    return `${totalHours}:${totalMinutesString}:${totalSecondsString}`;
  }

  public onUpdateNotifications(userNotifications: UserNotification[]) {
    console.log('PigProgressMarkers: onUpdateNotifications');

    this.tmpUserNotifications = userNotifications;
  }

  // incomming passage is from signalR, not exact Passage model
  public handleIncommingPassage(passageMessage: any, passageMarker: AgmMarker) {
    console.log('PigProgressMarkers: handleIncommingPassage');
    //logger.log(`${this.userInfo} handleIncommingPassage ${passageMessage.passageTimeStamp} ${passageMarker.name}`);

    console.log(passageMessage);
    if (this.pigRun && this.pigRun.markers) {
      // TODO we'll need a better way to convert to local time and keep this accuracy
      let timeFix = passageMessage.passageTimeStamp;
      if (timeFix.length === 30) {
        timeFix = timeFix.slice(0, -7);
      }
      if (timeFix.length === 29) {
        timeFix = timeFix.slice(0, -6);
      }
      if (timeFix.length === 28) {
        timeFix = timeFix.slice(0, -5);
      }
      if (timeFix.length === 27) {
        timeFix = timeFix.slice(0, -4);
      }
      if (timeFix.length === 26) {
        timeFix = timeFix.slice(0, -3);
      }
      if (timeFix.length === 25) {
        timeFix = timeFix.slice(0, -2);
      }
      if (timeFix.length === 24) {
        timeFix = timeFix.slice(0, -1);
      }

      // only add a single marker passage (up to 3 passage types coming at the same time)
      if (
        this.passageTimeStamps.indexOf(
          dayjs.utc(timeFix).format('YYYY-MM-DD HH:mm:ss.SSS')
        ) === -1
      ) {
        const isVerified = this.checkAutoVerify(passageMarker);
        if (isVerified) {
          // change the Passage in the DB to reflect the auto verification state
          passageMessage.isVerified = isVerified;
          //this.$store.dispatch('setPassageVerification', passageMessage);
        }
        this.addMarkerPassage(
          passageMarker.name,
          dayjs.utc(timeFix).format('YYYY-MM-DD HH:mm:ss.SSS'),
          isVerified,
          passageMessage.uid,
          passageMessage.passageId,
          passageMessage.detectedFrequency,
          passageMessage.detectionCount,
          passageMessage.packetComplexInformation
        );
        this.passageTimeStamps.push(
          dayjs.utc(timeFix).format('YYYY-MM-DD HH:mm:ss.SSS')
        );
      } else {
        // just updates the existing marker passage with either elfId/geoId/magId
        this.updateMarkerPassage(
          passageMarker.name,
          dayjs.utc(timeFix).format('YYYY-MM-DD HH:mm:ss.SSS'),
          passageMessage.uid,
          passageMessage.passageId,
          passageMessage.packetComplexInformation
        );
      }
    }
  }

  // incomming passage is from signalR, not exact Passage model
  public checkAutoLinked(passageMessage: any) {
    console.log('PigProgressMarkers: checkAutoLinked');
    const passageDevice: Device = this.devices.find(
      (d: Device) => d.uid === passageMessage.uid
    );
    if (
      passageDevice.lat &&
      passageDevice.lon &&
      passageDevice.lat !== 0 &&
      passageDevice.lon !== 0
    ) {
      const agmLocation = turf.point([passageDevice.lon, passageDevice.lat]);
      if (this.pigRun && this.pigRun.markers) {
        for (const marker of this.pigRun.markers) {
          if (marker.autoLink) {
            const markerLocation = turf.point([marker.lon, marker.lat]);
            const distance = turf.distance(agmLocation, markerLocation, {
              units: 'meters',
            });
            if (distance < this.autoLinkDistance) {
              this.handleIncommingPassage(passageMessage, marker);
            }
          }
        }
      }
    }
  }

  public checkAutoLinkUids() {
    // checks for autolinkuid change on each marker
    // let postUpdate = false;
    if (this.pigRun && this.pigRun.pigPath.agms && this.pigRun.markers) {
      const manualLinks = this.pigRun.markers
        .filter((m: AgmMarker) => m.linkedUid)
        .map((m: AgmMarker) => m.linkedUid);
      for (const marker of this.pigRun.markers) {
        if (marker.autoLink && !marker.linkedUid) {
          const currentAutoLinks = [...marker.autoLinkedUids!];
          const newAutoLinks = [];
          for (const agm of this.pigRun.pigPath.agms) {
            if (manualLinks.indexOf(agm) !== -1) {
              continue; // ignore anything with a manual link
            }
            const device: Device = this.devices.find(
              (d: Device) => d.uid === agm
            );
            if (device && device.lat && device.lon) {
              const agmLocation = turf.point([device.lon, device.lat]);
              const markerLocation = turf.point([marker.lon, marker.lat]);
              const distance = turf.distance(agmLocation, markerLocation, {
                units: 'meters',
              });
              if (distance < this.autoLinkDistance) {
                newAutoLinks.push(device.uid);
              }
            }
          }
          if (
            JSON.stringify(currentAutoLinks.sort()) !==
            JSON.stringify(newAutoLinks.sort())
          ) {
            marker.autoLinkedUids = newAutoLinks;
            // postUpdate = true;
          }
        }
        // undo when autolink turned off
        if (!marker.autoLink) {
          if (marker.autoLinkedUids && marker.autoLinkedUids.length) {
            marker.autoLinkedUids = [];
            // postUpdate = true;
          }
        }
      }
    }
    // autolink updates will not trigger DB update
    // if (postUpdate) {
    //     logger.log(`${this.userInfo} checkAutoLinkUids=>updatePigRun`);
    //     this.updatePigRun(true);
    // }
  }

  public checkAutoLinkStatus() {
    // checks for icon color changes
    if (this.pigRun && this.pigRun.pigPath.agms && this.pigRun.markers) {
      const manualLinks = this.pigRun.markers
        .filter((m: AgmMarker) => m.linkedUid)
        .map((m: AgmMarker) => m.linkedUid);
      const markersToCheck: AgmMarker[] = this.pigRun.markers.filter(
        (m: AgmMarker) => m.autoLink && !m.linkedUid
      );
      if (markersToCheck) {
        for (const agm of this.pigRun.pigPath.agms) {
          if (manualLinks.indexOf(agm) !== -1) {
            continue; // ignore anything with a manual link
          }
          const device: Device = this.devices.find(
            (d: Device) => d.uid === agm
          );
          if (device && device.lat && device.lon) {
            const agmLocation = turf.point([device.lon, device.lat]);
            let hasAutoLink = false;
            for (const marker of markersToCheck) {
              const markerLocation = turf.point([marker.lon, marker.lat]);
              const distance = turf.distance(agmLocation, markerLocation, {
                units: 'meters',
              });
              if (distance < this.autoLinkDistance) {
                hasAutoLink = true;
              }
            }
            if (hasAutoLink) {
              this.changeLinkStatus(agm, true);
            } else {
              this.changeLinkStatus(agm, false);
            }
          }
        }
      }
    }
  }

  public changeLinkStatus(statusUid: number, isLinked: boolean) {
    // turn the assigned icon RED/BLUE
    const device: Device = this.devices.find(
      (d: Device) => d.uid === statusUid
    );
    if (device && device.lat !== 0 && device.lon !== 0) {
      if (isLinked) {
        this.map.eachLayer((layer: any) => {
          if (layer.hasOwnProperty('_leaflet_id')) {
            if (this.leafletLayers[layer._leaflet_id] === device.uid) {
              if (device.isIridium) {
                if (device.isConnected) {
                  layer.setIcon(MapCommon.assignedIridiumOnlineIcon);
                } else {
                  layer.setIcon(MapCommon.assignedIridiumOfflineIcon);
                }
              } else {
                if (device.isConnected) {
                  layer.setIcon(MapCommon.assignedOnlineIcon);
                } else {
                  layer.setIcon(MapCommon.assignedOfflineIcon);
                }
              }
            }
          }
        });
      } else {
        // recover the unlinked icons
        this.map.eachLayer((layer: any) => {
          if (layer.hasOwnProperty('_leaflet_id')) {
            if (this.leafletLayers[layer._leaflet_id] === device.uid) {
              if (device.isIridium) {
                if (device.isConnected) {
                  layer.setIcon(MapCommon.agmIconOnlineIridium);
                } else {
                  layer.setIcon(MapCommon.agmIconOfflineIridium);
                }
              } else {
                if (device.isConnected) {
                  layer.setIcon(MapCommon.agmIconOnline);
                } else {
                  layer.setIcon(MapCommon.agmIconOffline);
                }
              }
            }
          }
        });
      }
    }
  }

  public checkAutoVerify(agmMarker: AgmMarker) {
    console.log('PigProgressMarkers: checkAutoVerify');
    // ONLY if autoVerify is ON and the pig is under 5 mins away then verify it
    if (
      agmMarker.autoVerify &&
      this.etaNext <= 60 * this.autoVerifyMinutes &&
      this.nextMarkerName &&
      this.nextMarkerName === agmMarker.name
    ) {
      return true;
    }
    return false;
  }

  public showAutoLinkRadius() {
    console.log('PigProgressMarkers: showAutoLinkRadius');
    this.hideAutoLinkRadius();
    if (this.pigRun && this.pigRun.markers) {
      this.pigRun.markers.forEach((marker: AgmMarker) => {
        if (marker.autoLink) {
          const autoLinkRadius = L.circle(
            [marker.lat, marker.lon],
            this.autoLinkDistance
          ).addTo(this.map);
          // TODO needs style?? <Circle options> options?
          this.autoLinkRadii.push(autoLinkRadius);
          if (this.pigRunLayers) {
            this.pigRunLayers.addLayer(autoLinkRadius);
          }
        }
      });
    }
  }

  public hideAutoLinkRadius() {
    console.log('PigProgressMarkers: hideAutoLinkRadius');
    this.autoLinkRadii.forEach((circle: L.Circle) => {
      circle.removeFrom(this.map);
      if (this.pigRunLayers) {
        this.pigRunLayers.removeLayer(circle);
      }
    });
    this.autoLinkRadii = [];
  }

  public onAgmSelectList() {
    console.log('onAgmSelectList');

    if (this.pigRun && this.pigRun.pigPath) {
      // what is currently selected
      this.tmpSelectedAgms = [...this.pigRun.pigPath.agms];

      // populate the available options
      this.agmUidOptions = [];
      this.devices.forEach((device: Device) => {
        this.agmUidOptions.push({
          label: 'AGM UID ' + device.uid,
          value: device.uid,
        });
      });

      this.addAgmsDialog = true;
    }
  }

  public onAgmListToggled() {
    if (this.tmpSelectedAgms && this.tmpSelectedAgms.length === 0) {
      this.tmpSelectedAgms = this.agmUidOptions.map(
        (option: any) => option.value
      );
    } else {
      this.tmpSelectedAgms = [];
    }
  }

  public onAgmListChanged() {
    if (this.pigRun && this.pigRun.pigPath) {
      // remove changes
      this.pigRun.pigPath.agms.forEach((removedUid: number) => {
        if (this.pigRun && !this.tmpSelectedAgms.includes(removedUid)) {
          this.pigRun.pigPath.agms = this.pigRun.pigPath.agms.filter(
            (uid: number) => uid !== removedUid
          );
        }
      });
      // add changes
      this.tmpSelectedAgms.forEach((changedUid: number) => {
        if (this.pigRun && !this.pigRun.pigPath.agms.includes(changedUid)) {
          this.pigRun.pigPath.agms.push(changedUid);
        }
      });

      // this.updatePigRunAgms();
      //logger.log(`${this.userInfo} onAgmListChanged=>updatePigRun`);
      this.updatePigRun(true);
    }
  }

  public onSaveUserNotifications() {
    if (this.pigRun && this.pigRun.pigPath) {
      this.pigRun.pigPath.notifications = this.tmpUserNotifications;
      //logger.log(`${this.userInfo} onSaveUserNotifications=>updatePigRun`);
      this.updatePigRun(false);
    }
  }

  public onAutoSettings() {
    console.log('PigProgressMarkers: onAutoSettings');
    this.$q
      .dialog({
        component: AutoSettings,
        parent: this,
        markers: this.pigRun!.markers,
      })
      .onOk((markers: AgmMarker[]) => {
        console.log('OK');
        this.pigRun!.markers = markers;
        this.showAutoLinkRadius();
        //logger.log(`${this.userInfo} onAutoSettings=>updatePigRun`);
        this.updatePigRun(false);
      })
      .onCancel(() => {
        console.log('Cancel');
      });
  }

  public onChangeMarkerDescription(payload: any) {
    const markerName = payload.markerName;
    const description = payload.description;
    if (this.pigRun && this.pigRun.markers) {
      this.pigRun.markers.forEach((marker: AgmMarker) => {
        if (marker.name === markerName) {
          marker.description = description;
        }
      });
      // also need to update the markers in the path so updated ETAs can be calculated
      this.pigRun.pigPath.markers = this.pigRun.markers;

      this.selectedMarkerName = '';

      // a marker state change requires an complete update
      //logger.log(`${this.userInfo} onChangeMarkerDescription=>updatePigRun`);
      this.updatePigRun(false);
    }
  }

  public onChangeMarkerState(data: any) {
    var requested_runid = data[0];
    var markername = data[1];
    console.log('PigProgressMarkers.vuw:onChangeMarkerState');
    if (this.pigRun) {
      if (this.pigRun.runId == requested_runid && this.pigRun.markers) {
        this.pigRun.markers.forEach((marker: AgmMarker) => {
          if (marker.name === markername) {
            if (marker.state === 'SKIP') {
              console.log(
                'PigProgressMarkers.vuw:onChangeMarkerState: Change to TRACK!!!!'
              );
              marker.state = 'TRACK';
            } else {
              console.log(
                'PigProgressMarkers.vuw:onChangeMarkerState: Change to SKIP!!!!'
              );
              marker.state = 'SKIP';
              marker.autoLink = false;
              marker.autoVerify = false;
              this.showAutoLinkRadius();
            }
            // remove any UID linking on state changes
            if (marker.linkedUid) {
              const uid = marker.linkedUid;
              if (uid) {
                const device = this.devices.find((d: Device) => d.uid === uid);
                if (device && device.lat !== 0 && device.lon !== 0) {
                  this.map.eachLayer((layer: any) => {
                    if (layer.hasOwnProperty('_leaflet_id')) {
                      if (
                        this.leafletLayers[layer._leaflet_id] === device.uid
                      ) {
                        if (device.isIridium) {
                          if (device.isConnected) {
                            layer.setIcon(MapCommon.agmIconOnline);
                          } else {
                            layer.setIcon(MapCommon.agmIconOffline);
                          }
                        } else {
                          if (device.isConnected) {
                            layer.setIcon(MapCommon.agmIconOnline);
                          } else {
                            layer.setIcon(MapCommon.agmIconOffline);
                          }
                        }
                      }
                    }
                  });
                }
              }
              marker.linkedUid = undefined;
            }
          }
        });

        // also need to update the markers in the path so updated ETAs can be calculated
        this.pigRun.pigPath.markers = this.pigRun.markers;
        this.selectedMarkerName = '';

        // also need to check if PigRun is in Phase 1 and if Edited marker correspond to
        // EditNextMarkerIndex or EditNextMarkerIndex2. Cause this will effect how PigRunManager handles things

        if (
          this.pigRun.EditNextMarkerIndex != null &&
          markername ==
            this.pigRun.markers[this.pigRun.EditNextMarkerIndex].name
        ) {
          // request to change EditNextMarkerIndex to skip
          // As a result we need to re-evaluate EditNextMarkerIndex & EditNextMarkerIndex2
          var pigloc = this.pigMarker?.getLatLng();
          if (pigloc) {
            this.GetnextMarker(pigloc.lat, pigloc.lng);
            this.pigRun.EditNextMarkerIndex = this.next_index;
            this.pigRun.EditNextMarkerIndex2 = this.next_index2;
          }
        } else if (
          this.pigRun.EditNextMarkerIndex2 != null &&
          markername ==
            this.pigRun.markers[this.pigRun.EditNextMarkerIndex2].name
        ) {
        }

        // a marker state change requires an complete update
        this.updatePigRun(false);
      }
    }
  }

  public GetnextMarker(lat: number, lng: number) {
    this.next_index = -1;
    this.next_index2 = -1;

    if (
      this.pigRun != null &&
      this.pigRun.markers != null &&
      this.launchMarker != null &&
      this.pigMarker != null
    ) {
      const linestringg = turfHelpers.lineString(
        this.pigRun?.pigPath.pigLineGenerated
      );
      var Launch_pt = turf.point([
        this.pigRun.pigPath.launchSiteLon,
        this.pigRun.pigPath.launchSiteLat,
      ]);
      //var temp_pt =  turf.point([pigloc?.lng,pigloc?.lat]);//////////////
      var temp_pt = turf.point([lng, lat]);
      // Need to find the next and prev marker locations
      var sliced = turf.lineSlice(Launch_pt, temp_pt, linestringg);
      var distance_to_temp = turf.lineDistance(sliced, { units: 'meters' });
      for (var i = 0; i < this.pigRun.markers?.length; i++) {
        var marker_pt = turf.point([
          this.pigRun.markers[i].lon,
          this.pigRun.markers[i].lat,
        ]);
        var sliced = turf.lineSlice(Launch_pt, marker_pt, linestringg);
        var distance = turf.lineDistance(sliced, { units: 'meters' }); // distance between launch and first marker pt

        if (
          distance_to_temp < distance &&
          this.pigRun.markers[i].state == 'TRACK'
        ) {
          this.next_index = i;
          break;
        }
      }

      if (
        this.next_index != null &&
        this.next_index != -1 &&
        this.next_index + 1 < this.pigRun.markers.length
      ) {
        for (
          var i = this.next_index + 1;
          i < this.pigRun.markers?.length;
          i++
        ) {
          if (this.pigRun.markers[i].state == 'TRACK') {
            this.next_index2 = i;
            break;
          }
        }
      }
    }
  }

  public GetnextMarkerV2(lat: number, lng: number) {
    // Get next index without having to look at state
    var next_index = -1;

    if (
      this.pigRun != null &&
      this.pigRun.markers != null &&
      this.launchMarker != null &&
      this.pigMarker != null
    ) {
      const linestringg = turfHelpers.lineString(
        this.pigRun?.pigPath.pigLineGenerated
      );
      var Launch_pt = turf.point([
        this.pigRun.pigPath.launchSiteLon,
        this.pigRun.pigPath.launchSiteLat,
      ]);
      var temp_pt = turf.point([lng, lat]);
      // Need to find the next and prev marker locations
      var sliced = turf.lineSlice(Launch_pt, temp_pt, linestringg);
      var distance_to_temp = turf.lineDistance(sliced, { units: 'meters' });
      for (var i = 0; i < this.pigRun.markers?.length; i++) {
        var marker_pt = turf.point([
          this.pigRun.markers[i].lon,
          this.pigRun.markers[i].lat,
        ]);
        var sliced = turf.lineSlice(Launch_pt, marker_pt, linestringg);
        var distance = turf.lineDistance(sliced, { units: 'meters' }); // distance between launch and first marker pt

        if (distance_to_temp < distance) {
          next_index = i;
          break;
        }
      }
    }
    return next_index;
  }

  public onShowNoteDialog(markerName: string, markerNote?: string) {
    this.addNoteDialog = true;
    this.selectedMarkerName = markerName;
    this.noteText = markerNote ? markerNote : '';
  }

  public onAssignUidDialog(markerName: string, linkedUid?: number) {
    this.assignUidDialog = true;
    this.selectedMarkerName = markerName;
    this.selectedUid = '';
  }

  public onUnlinkUid(markerName: string) {
    if (this.pigRun && this.pigRun.markers) {
      this.pigRun.markers.forEach((marker: AgmMarker) => {
        if (marker.name === markerName) {
          const uid = marker.linkedUid;
          marker.linkedUid = undefined;
          if (uid) {
            const device = this.devices.find((d: Device) => d.uid === uid);
            if (device && device.lat !== 0 && device.lon !== 0) {
              this.map.eachLayer((layer: any) => {
                if (layer.hasOwnProperty('_leaflet_id')) {
                  if (this.leafletLayers[layer._leaflet_id] === device.uid) {
                    if (device.isIridium) {
                      if (device.isConnected) {
                        layer.setIcon(MapCommon.agmIconOnlineIridium);
                      } else {
                        layer.setIcon(MapCommon.agmIconOfflineIridium);
                      }
                    } else {
                      if (device.isConnected) {
                        layer.setIcon(MapCommon.agmIconOnline);
                      } else {
                        layer.setIcon(MapCommon.agmIconOffline);
                      }
                    }
                  }
                }
              });
            }
          }
          //logger.log(`${this.userInfo} onUnlinkUid=>updatePigRun`);
          this.updatePigRun(true);
        }
      });
    }
  }

  public onSaveNote() {
    console.log('PigProgressMarkers: onSaveNote()');
    if (this.pigRun && this.pigRun.markers) {
      this.pigRun.markers.forEach((marker: AgmMarker) => {
        if (marker.name === this.selectedMarkerName) {
          if (this.noteText.length > 200) {
            this.noteText = this.noteText.substring(0, 199);
          }
          marker.note = this.noteText;
          //logger.log(`${this.userInfo} onSaveNote=>updatePigRun`);
          this.updatePigRun(true);
        }
      });
    }
    this.selectedMarkerName = '';
  }

  public onSaveUidLink() {
    console.log('PigProgressMarkers: onSaveUidLink()');
    if (this.pigRun && this.pigRun.markers) {
      this.pigRun.markers.forEach((marker: AgmMarker) => {
        if (marker.name === this.selectedMarkerName) {
          marker.linkedUid = parseInt(this.selectedUid, 10);
          //logger.log(`${this.userInfo} onSaveUidLink=>updatePigRun`);
          this.updatePigRun(true);
        }
      });
    }
    // turn the assigned icon RED/BLUE
    const device: Device = this.devices.find(
      (d: Device) => d.uid === parseInt(this.selectedUid, 10)
    );
    if (device && device.lat !== 0 && device.lon !== 0) {
      this.map.eachLayer((layer: any) => {
        if (layer.hasOwnProperty('_leaflet_id')) {
          if (this.leafletLayers[layer._leaflet_id] === device.uid) {
            console.log('linking device ' + device.uid);
            if (device.isIridium) {
              if (device.isConnected) {
                layer.setIcon(MapCommon.assignedIridiumOnlineIcon);
              } else {
                layer.setIcon(MapCommon.assignedIridiumOfflineIcon);
              }
            } else {
              if (device.isConnected) {
                layer.setIcon(MapCommon.assignedOnlineIcon);
              } else {
                layer.setIcon(MapCommon.assignedOfflineIcon);
              }
            }
          }
        }
      });
    }
    this.selectedMarkerName = '';
  }

  public isFurthestVerifiedPassage(marker: string) {
    // prevents a jump backwards if we go verify old data
    if (
      this.pigRun &&
      this.pigRun.markers &&
      this.pigRun.passages &&
      this.pigRun.passages.length > 0
    ) {
      const markerOrder = this.pigRun.markers.map((m: AgmMarker) => m.name);
      const position = markerOrder.indexOf(marker);
      if (position >= this.furthestVerifiedPosition) {
        this.furthestVerifiedPosition = position;
        return true;
      }
    }
    return false;
  }

  public addMarkerPassage(
    marker: string,
    timestamp: string,
    isVerified: boolean,
    uid?: number,
    passageId?: number,
    detectedFrequency?: number,
    detectionCount?: number,
    packetComplexInformation?: number
  ) {
    console.log('PigProgressMarkers: addMarkerPassage()');

    if (this.pigRun && this.pigRun.passages) {
      const markerPassage = new MarkerPassage(
        marker,
        timestamp,
        uid,
        isVerified,
        passageId,
        detectedFrequency,
        detectionCount,
        packetComplexInformation
      );
      if (packetComplexInformation === 0) {
        // ELF
        markerPassage.elfId = passageId;
      } else if (packetComplexInformation === 1) {
        markerPassage.geoId = passageId;
      } else if (packetComplexInformation === 2) {
        markerPassage.magId = passageId;
      }

      this.pigRun.passages.push(markerPassage);

      // this passage needs to get saved, but do it after calulatedSpeed
      // this.$store.dispatch('upsertPigRun', this.pigRun);

      // a manual passage can be triggered from popup
      this.markerLayers[marker].closePopup();

      this.updatePigRun(true);
      /*if (this.pigRun && isVerified) {
                this.recalculateSpeedAndEtas(marker, timestamp);
            } else {
                // unverified passage should still be saved as part of pig run
                //logger.log(`${this.userInfo} addMarkerPassage=>updatePigRun`);
                this.updatePigRun(true);
            }*/
    }
  }

  public CheckifMarkerHasPsg(selectedmarkerName: string | null) {
    var index = -1;
    //var selectedmarkerName = this.passageMarker;

    if (this.pigRun && this.pigRun.passages) {
      index = this.pigRun.passages.findIndex((passage: MarkerPassage) => {
        if (passage.markerName == selectedmarkerName) {
          if (passage.isVerified == true) {
            return true;
          }
        }
      });

      if (index < 0) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  public CheckifBeforeLaunch(psgtime_GMT: string) {
    if (this.pigRun && this.pigRun.launchTime) {
      //var psgtime_GMT  = this.EditManualPsgTimestamp + ' GMT';
      var launchTime = new Date(this.pigRun.launchTime.toUTCString());
      var NewPsgTime = new Date(psgtime_GMT);
      var diff = NewPsgTime.getTime() - launchTime.getTime();
      if (diff <= 0) {
        // Error Passage Time is before Launch Time
        return true;
      }
    }
    return false;
  }

  public CheckifinFuture(psgtime_GMT: string) {
    if (this.pigRun) {
      //var psgtime_GMT  = this.EditManualPsgTimestamp + ' GMT';
      var NowDate: Date;
      NowDate = new Date();
      var NewPsgTime = new Date(psgtime_GMT);
      var diff = NewPsgTime.getTime() - NowDate.getTime();
      if (diff > 0) {
        // Error Passage Time is before Launch Time
        console.log('Manual passage Time is before Launch Time');
        return true;
      }
    }
    return false;
  }

  public CheckifAfterNextVerifiedPsg(
    psgtime_GMT: string,
    selectedmarkerName: string | null
  ) {
    if (this.pigRun && this.pigRun.passages) {
      var index = -1;
      var current_marker_index = -1;
      console.log(selectedmarkerName);
      // First step we need to find where this passage in the list
      if (this.pigRun.markers) {
        current_marker_index = this.pigRun.markers?.findIndex((x) => {
          if (x.name === selectedmarkerName) {
            return true;
          }
        });
        if (current_marker_index < 0) {
          return false; // selected marker name does not exist in the list
          // We are not going to proceed checking
        } else {
          // It is in the list and we know the index => current_marker_index
          for (
            var i = current_marker_index + 1;
            i < this.pigRun.markers.length;
            i++
          ) {
            if (this.pigRun.markers[i].state == 'TRACK') {
              for (var j = 0; j < this.pigRun.passages.length; j++) {
                if (
                  this.pigRun.markers[i].name ==
                  this.pigRun.passages[j].markerName
                ) {
                  // We have a match between TRACK marker name and a passage in list
                  if (this.pigRun.passages[j].isVerified == true) {
                    // Now we make the comparison
                    var psgTime = new Date(
                      this.pigRun.passages[j].passageTimeStamp + ' GMT'
                    );
                    var EditManualPsgTime = new Date(psgtime_GMT);
                    var diff = psgTime.getTime() - EditManualPsgTime.getTime();
                    if (diff <= 0) {
                      // if edited manual psg time is greater than a verieifed psg time then error
                      return true; /// An error case exist
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return false;
  }

  public CheckifVerifiedExist() {
    if (this.passageMarker && this.pigRun && this.pigRun.passages) {
      var index = -1;
      //var psgtime_GMT  = this.EditManualPsgTimestamp + ' GMT';
      index = this.pigRun.passages.findIndex((x) => {
        if (x.isVerified === true && x.markerName === this.passageMarker) {
          return true;
        }
      });
    }
    return false;
  }

  public CheckifBeforePreviousVerifiedPsg(
    psgtime_GMT: string,
    selectedmarkerName: string | null
  ) {
    if (this.pigRun && this.pigRun.passages) {
      // In this case we should only look at passages that correspond to locations between launch and current location (no future points)
      var current_marker_index = -1;

      if (this.pigRun.markers) {
        current_marker_index = this.pigRun.markers?.findIndex((x) => {
          if (x.name === selectedmarkerName) {
            return true;
          }
        });

        if (current_marker_index < 0) {
          return false; // selected marker name does not exist in the list
          // We are not going to proceed checking
        } else {
          // It is in the list and we know the index => current_marker_index
          for (var i = 0; i < current_marker_index; i++) {
            if (this.pigRun.markers[i].state == 'TRACK') {
              for (var j = 0; j < this.pigRun.passages.length; j++) {
                if (
                  this.pigRun.markers[i].name ==
                  this.pigRun.passages[j].markerName
                ) {
                  // We have a match between TRACK marker name and a passage in list
                  if (this.pigRun.passages[j].isVerified == true) {
                    // Now we make the comparison
                    var psgTime = new Date(
                      this.pigRun.passages[j].passageTimeStamp + ' GMT'
                    );
                    var EditManualPsgTime = new Date(psgtime_GMT);
                    var diff = EditManualPsgTime.getTime() - psgTime.getTime();

                    if (diff <= 0) {
                      // if edited manual psg time is less than a verieifed psg time then error
                      return true; /// An error case exist
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return false;
  }

  public addEditMarkerPassage(passageMarker: MarkerPassage) {
    console.log('PigProgressMarkers: addEditMarkerPassage()');

    // Check 1 does this marker has previously verified passage?
    /*if(this.CheckifMarkerHasPsg() == true)
        {
            // Error
            
            console.log('PigProgressMarker: This marker has previously verified passage')
            this.ManualPsg_ErrorMsg = 'This marker has previously verified passage';
            this. EditManual_ErrorMsg = true;
            return;
        }*/

    // Check 2: Check to see if this.EditManualPsgTimestamp is before launch time
    if (
      this.CheckifBeforeLaunch(this.EditManualPsgTimestamp + ' GMT') == true
    ) {
      // Error
      console.log(
        'PigProgressMarker: Manual passage Time is before Launch Time'
      );
      this.ManualPsg_ErrorMsg = 'Entered Time is before Launch Time';
      this.EditManual_ErrorMsg = true;
      return;
    }

    // Check 3: Check to see if this.EditManualPsgTimestamp is in the future
    if (this.CheckifinFuture(this.EditManualPsgTimestamp + ' GMT') == true) {
      // Error
      console.log('PigProgressMarker: Manual passage Time is in the future');
      this.ManualPsg_ErrorMsg = 'Entered Time is in the Future';
      this.EditManual_ErrorMsg = true;
      return;
    }

    // Check 4: Check to see if this.EditManualPsgTimestamp is a time that occured before any previous verified passages

    if (
      this.CheckifBeforePreviousVerifiedPsg(
        this.EditManualPsgTimestamp + ' GMT',
        this.selectedMarkerName
      ) == true
    ) {
      // Error
      console.log('PigProgressMarker: Manual passage Time is Invalid');
      this.ManualPsg_ErrorMsg =
        'Entered Time is before of a previous Verified Psg';
      this.EditManual_ErrorMsg = true;
      return;
    }

    // Check 5: Check to see if this.EditManualPsgTimestamp is a time that occured after the next verified passage location
    if (
      this.CheckifAfterNextVerifiedPsg(
        this.EditManualPsgTimestamp + ' GMT',
        this.selectedMarkerName
      ) == true
    ) {
      // Error
      console.log('PigProgressMarker: Manual passage Time is Invalid');
      this.ManualPsg_ErrorMsg =
        'Entered Time is ahead of a further Verified Psg';
      this.EditManual_ErrorMsg = true;
      return;
    }

    this.EditManual_ErrorMsg = false;

    if (this.pigRun && this.pigRun.passages && passageMarker) {
      var index = this.pigRun.passages.findIndex(
        (x) =>
          x.markerName === passageMarker.markerName &&
          x.passageTimeStamp == passageMarker.passageTimeStamp
      );
      var value = this.pigRun.passages.splice(index, 1);
      if (value.length == 1) {
        value[0].passageTimeStamp = this.EditManualPsgTimestamp;
        this.pigRun.passages.push(passageMarker);
        this.markerLayers[passageMarker.markerName].closePopup();
        this.updatePigRun(true);
        this.editConfirmDialog = false;
      }
    }
  }

  public updateMarkerPassage(
    marker: string,
    timestamp: string,
    uid: number,
    passageId: number,
    packetComplexInformation: number
  ) {
    console.log('PigProgressMarkers: updateMarkerPassage()');
    if (this.pigRun && this.pigRun.passages) {
      const idx = this.pigRun.passages.findIndex(
        (markerPassage: MarkerPassage) =>
          markerPassage.passageTimeStamp === timestamp
      );
      if (idx !== -1) {
        if (packetComplexInformation === 0) {
          // ELF
          this.pigRun.passages[idx].elfId = passageId;
        } else if (packetComplexInformation === 1) {
          this.pigRun.passages[idx].geoId = passageId;
        } else if (packetComplexInformation === 2) {
          this.pigRun.passages[idx].magId = passageId;
        }
        // logger.log(`${this.userInfo} updateMarkerPassage=>updatePigRun`);
        this.updatePigRun(true);
      }
    }
  }

  public recalculateSpeedAndEtas(marker: string, timestamp: string) {
    console.log(
      'PigProgressMarkers: recalculateSpeedAndEtas() ' +
        marker +
        '/' +
        timestamp
    );
    if (this.pigRun && this.pigMarker) {
      if (this.isFurthestVerifiedPassage(marker)) {
        // don't jump backwards
        console.log('advance!');
        const markerInfo = this.pigRun.pigPath.getMarkerInfo();
        console.log(markerInfo);
        const timeToPassage = dayjs
          .utc(timestamp)
          .diff(dayjs.default(this.pigRun.launchTime).utc(), 'second');
        console.log('TTP:' + timeToPassage);
        this.pigRun.calculatedSpeed =
          markerInfo[marker].distanceFromStart / timeToPassage;

        // instead of snapping to agm location set the marker to elapsed time based on this new speed
        const line = turfHelpers.lineString(
          this.pigRun.pigPath.pigLineGenerated
        );
        const newLocation = along(
          line,
          this.pigRun.calculatedSpeed * this.elapsedSeconds,
          { units: 'meters' }
        );
        const nextMarkerLatLng = markerInfo[marker].nextMarker
          ? markerInfo[markerInfo[marker].nextMarker].latLng
          : null; // get the next marker latlng or null

        // if the new location is beyond the next AGM... go wait at the next unverified agm
        if (
          nextMarkerLatLng &&
          this.pigRun.calculatedSpeed * this.elapsedSeconds >
            markerInfo[markerInfo[marker].nextMarker].distanceFromStart
        ) {
          console.log('Expected beyond the next Marker');
          //this.pigMarker.setLatLng(nextMarkerLatLng);
        } else {
          //this.pigMarker.setLatLng(L.latLng(newLocation.geometry.coordinates[1], newLocation.geometry.coordinates[0]));
        }

        this.nextState = markerInfo[marker].nextMarker
          ? markerInfo[markerInfo[marker].nextMarker].state
          : 'TRACK';
        this.nextMarkerName = markerInfo[marker].nextMarker;
        // TEST IHAB//
        this.previousMarkerName = marker;
        // TEST IHAB//
        this.calculateNextMarkerTime(markerInfo[marker].distanceToNext);
        this.calculateCompleteTime();
      } else {
        console.log(
          'Im not moving because Ive been verified past this already'
        );
      }
      // save after recalculation of speed
      //logger.log(`${this.userInfo} recalculateSpeedAndEtas=>updatePigRun`);
      this.updatePigRun(true);
    }
  }

  public updateLocation() {
    console.log('PigProgressMarkers: updateLocation() ' + this.elapsedSeconds);
    this.timerId = setInterval(() => {
      if (this.pigRun) {
        //console.log(this.pigRun);
        this.elapsedSeconds++;

        // check autolinks icon changes every 10s
        if (this.elapsedSeconds % 10 === 0) {
          this.checkAutoLinkStatus();
          //this.checkAutoLinkUids();
        }

        // hold pig and wait for passage if we are at expected Marker/recieve site
        if (this.nextState === 'SKIP' && this.etaNext <= 0) {
          // add a dummy passage
          console.log('skip please');
          // recalculateSpeedAndEtas()
        }

        //Test Ihab//
        //this.IhabInsSpeed();
        //Test Ihab//

        if (
          (this.etaNext > 0 && this.etaComplete > 0) ||
          (this.etaNext === -1 && this.etaComplete > 0)
        ) {
          if (this.etaNext !== -1) {
            //this.etaNext--; // skip if no etaNext
          }
          //this.etaComplete--;
          const speed =
            this.pigRun.calculatedSpeed && this.pigRun.calculatedSpeed > 0
              ? this.pigRun.calculatedSpeed
              : this.pigRun.pigPath.expectedSpeed;
          //this.distanceFromStart = speed * this.elapsedSeconds;
          var time;
          time = dayjs
            .default()
            .diff(dayjs.default(this.pigRun.launchTime), 'second'); // Difference between Time Now & Launch Time

          this.distanceFromStart = speed * time;

          const line = turfHelpers.lineString(
            this.pigRun.pigPath.pigLineGenerated
          );
          const newLocation = along(line, this.distanceFromStart, {
            units: 'meters',
          });
          if (this.pigMarker) {
            // NOTE: lat/lon are flipped in turf
            const nextMarkerInfo = this.markerInfo[this.nextMarkerName!];

            if (
              this.nextMarkerName &&
              this.distanceFromStart > nextMarkerInfo.distanceFromStart
            ) {
              //this.pigMarker.setLatLng(L.latLng(nextMarkerInfo.latLng.lat, nextMarkerInfo.latLng.lng));
            } else {
              //this.pigMarker.setLatLng(L.latLng(newLocation.geometry.coordinates[1], newLocation.geometry.coordinates[0]));
            }
          }
        }
      }
    }, this.refershFrequency) as any;
  }

  public restoreMarkerLayers() {
    const markerLayers: { [key: string]: L.Marker } = {};
    if (this.pigRun && this.pigRun.markers) {
      for (const agmMarker of this.pigRun.markers) {
        if (agmMarker.state === 'SKIP') {
          let icon;
          icon = PigRunCommon.skipIcon;
          if (agmMarker.hasOwnProperty('description')) {
            if (
              typeof agmMarker.description !== 'undefined' &&
              agmMarker.description !== 'AGM'
            ) {
              icon = PigRunCommon.getIcon(agmMarker.description!);
            }
          }
          var skipMarker;
          if (agmMarker.hasOwnProperty('description')) {
            if (
              typeof agmMarker.description !== 'undefined' &&
              agmMarker.description !== 'AGM'
            ) {
              skipMarker = L.marker(L.latLng(agmMarker.lat, agmMarker.lon), {
                icon,
                opacity: 0.0,
              })
                .bindTooltip(agmMarker.name)
                .addTo(this.map)
                .on('click', (evt) => {
                  this.$store.dispatch('setSelectedMarker', agmMarker);
                });
            } else {
              skipMarker = L.marker(L.latLng(agmMarker.lat, agmMarker.lon), {
                icon,
                opacity: 0.8,
              })
                .bindTooltip(agmMarker.name)
                .addTo(this.map)
                .on('click', (evt) => {
                  this.$store.dispatch('setSelectedMarker', agmMarker);
                });
            }
          }
          if (skipMarker != undefined) {
            this.agmMapMarkers.push(skipMarker);
            markerLayers[agmMarker.name] = skipMarker;

            /////added by Ihab///////
            if (this.pigRunLayers) {
              this.pigRunLayers.addLayer(skipMarker);
            }
            /////added by Ihab///////
          }
        } else {
          // TRACK
          const trackMarker = L.marker(L.latLng(agmMarker.lat, agmMarker.lon), {
            icon: PigRunCommon.getIcon('TRACK'),
            opacity: 0.8,
          })
            .bindTooltip(agmMarker.name)
            .addTo(this.map)
            .on('click', (evt) => {
              this.$store.dispatch('setSelectedMarker', agmMarker);
            });
          this.agmMapMarkers.push(trackMarker);
          markerLayers[agmMarker.name] = trackMarker;
          /////added by Ihab///////
          if (this.pigRunLayers) {
            this.pigRunLayers.addLayer(trackMarker);
          }
          /////added by Ihab///////
        }

        if (agmMarker.linkedUid) {
          // show linked devices as red/blue
          const device: Device = this.devices.find(
            (d: Device) => d.uid === agmMarker.linkedUid
          );
          if (device && device.lat !== 0 && device.lon !== 0) {
            this.map.eachLayer((layer: any) => {
              if (layer.hasOwnProperty('_leaflet_id')) {
                if (this.leafletLayers[layer._leaflet_id] === device.uid) {
                  if (device.isIridium) {
                    if (device.isConnected) {
                      layer.setIcon(MapCommon.assignedIridiumOnlineIcon);
                    } else {
                      layer.setIcon(MapCommon.assignedIridiumOfflineIcon);
                    }
                  } else {
                    if (device.isConnected) {
                      layer.setIcon(MapCommon.assignedOnlineIcon);
                    } else {
                      layer.setIcon(MapCommon.assignedOfflineIcon);
                    }
                  }
                }
              }
            });
          }
        }
      }
      var h = [];
      h.push(this.pigRun);
      h.push(markerLayers);
      this.$store.dispatch('setMarkerLayers', h);
    }
  }

  public setupPigRun() {
    console.log('PigProgressMarkers: setupPigRun()');
    if (this.pigRun) {
      this.pigRunPath = L.polyline(
        this.pigRun.pigPath.generatedLineToLatLngs(),
        PigRunCommon.pigRunOptions
      );
      this.map.fitBounds(this.pigRunPath.getBounds());
      this.distanceTotal = this.pigRun.pigPath.getDistance();
      this.launchMarker = L.marker(this.pigRun.pigPath.launchSiteToLatLng(), {
        icon: PigRunCommon.launchIcon,
        zIndexOffset: -5,
      });
      this.recieveMarker = L.marker(this.pigRun.pigPath.recieveSiteToLatLng(), {
        icon: PigRunCommon.recieveIcon,
        zIndexOffset: -5,
      });
      if (this.pigMarker == null || this.pigMarker == undefined) {
        this.pigMarker = L.marker(this.pigRun.pigPath.launchSiteToLatLng(), {
          icon: PigRunCommon.pigIcon,
          zIndexOffset: -4,
        });
      }
      if (this.pigRun.pigPath.notifications) {
        this.userNotifications = this.pigRun.pigPath.notifications;
      }
      this.restoreMarkerLayers();
      const layerList = [
        this.pigRunPath,
        this.launchMarker,
        this.recieveMarker,
        this.pigMarker,
      ]
        .concat(Object.values(this.markerLayers))
        .concat(Object.values(this.offlineAgms));
      console.log('pigProgressMarkers.vue:layerList');
      console.log(layerList);
      this.pigRunLayers = L.layerGroup(layerList);

      console.log('pigProgressMarkers.vue: SetupPigRunV - before dispatch');
      this.pigRunLayers.on('add', () => {
        console.log('PigProgressMarkers.vue:setupPigRun: Add Event');
        console.log(this.pigRunLayers);
        this.$store.dispatch('pigProgressMaximizeRequest', this.pigRun?.runId);
      });
      //this.pigRunLayers.on("remove",() => this.RqstMin(this.position));
      this.pigRunLayers.on('remove', () => {
        console.log('PigProgressMarkers.vue:setupPigRun: Remove Event');
        console.log(this.pigRunLayers);
        this.$store.dispatch('pigProgressMinimizeRequest', this.pigRun?.runId);
      });

      this.pigRunLayers.addTo(this.map);
      // this adds the pig run layers to the base layers associated with the Secondary layerscontrol in leafletmap.vue
      this.layerControl.addBaseLayer(
        this.pigRunLayers,
        `${this.pigRun.pig} on ${this.pigRun.pigPath.pigRunName}`
      );
      //this.layerControl.addOverlay(this.pigRunLayers, `${this.pigRun.pig} on ${this.pigRun.pigPath.pigRunName}`);
      this.showAutoLinkRadius(); // =>Handles adding circles to this.pigRunLayers
      this.restorePigRunV3(); //=> Handles ETAs
      this.updateLocation(); // =>Start Timer for AutoLinked UIDs
      this.$store.dispatch('pigRunsReady');
    }
  }

  public setupPigRun_V2() {
    console.log('PigProgressMarkers: setupPigRun V2()');
    if (this.pigRun) {
      this.pigRunPath = L.polyline(
        this.pigRun.pigPath.generatedLineToLatLngs(),
        PigRunCommon.pigRunOptions
      );
      this.distanceTotal = this.pigRun.pigPath.getDistance();
      this.launchMarker = L.marker(this.pigRun.pigPath.launchSiteToLatLng(), {
        icon: PigRunCommon.launchIcon,
        zIndexOffset: -5,
      });
      this.recieveMarker = L.marker(this.pigRun.pigPath.recieveSiteToLatLng(), {
        icon: PigRunCommon.recieveIcon,
        zIndexOffset: -5,
      });
      if (this.pigMarker == null && this.pigMarker == undefined) {
        this.pigMarker = L.marker(this.pigRun.pigPath.launchSiteToLatLng(), {
          icon: PigRunCommon.pigIcon,
          zIndexOffset: -4,
        });
      }

      if (this.pigRun.pigPath.notifications) {
        this.userNotifications = this.pigRun.pigPath.notifications;
      }
      this.restoreMarkerLayers();
      const layerList = [
        this.pigRunPath,
        this.launchMarker,
        this.recieveMarker,
        this.pigMarker,
      ]
        .concat(Object.values(this.markerLayers))
        .concat(Object.values(this.offlineAgms));

      this.pigRunLayers = L.layerGroup(layerList);

      this.pigRunLayers.on('add', () => {
        console.log('PigProgressMarkers.vue:setupPigRun_V2: Add Event');
        console.log(this.pigRunLayers);
        this.$store.dispatch('pigProgressMaximizeRequest', this.pigRun?.runId);
      });
      //this.pigRunLayers.on("remove",() => this.RqstMin(this.position));
      this.pigRunLayers.on('remove', () => {
        console.log('PigProgressMarkers.vue:setupPigRun_V2: Remove Event');
        console.log(this.pigRunLayers);
        this.$store.dispatch('pigProgressMinimizeRequest', this.pigRun?.runId);
      });

      console.log('pigProgressMarkers.vue: SetupPigRunV2 - before dispatch');

      this.pigRunLayers.addTo(this.map);
      // this adds the pig run layers to the base layers associated with the Secondary layerscontrol in leafletmap.vue
      this.layerControl.addBaseLayer(
        this.pigRunLayers,
        `${this.pigRun.pig} on ${this.pigRun.pigPath.pigRunName}`
      );
      //this.layerControl.addOverlay(this.pigRunLayers, `${this.pigRun.pig} on ${this.pigRun.pigPath.pigRunName}`);
      this.showAutoLinkRadius(); // =>Handles adding circles to this.pigRunLayers
      this.restorePigRunV3(); //=> Handles ETAs
      this.updateLocation(); // =>Start Timer for AutoLinked UIDs
      this.$store.dispatch('pigRunsReady');
    }
  }

  public restorePigRunV3() {
    if (this.pigRun && this.pigMarker) {
      if (this.pigRun.pigPath.notifications) {
        this.userNotifications = this.pigRun.pigPath.notifications;
      }

      //const markerInfo = this.pigRun.pigPath.getMarkerInfo();
      //const lastPassage = this.getLastVerifiedPassage();
      //const speed = this.pigRun.calculatedSpeed && this.pigRun.calculatedSpeed > 0 ? this.pigRun.calculatedSpeed : this.pigRun.pigPath.expectedSpeed;
      this.elapsedSeconds = dayjs
        .default()
        .diff(dayjs.default(this.pigRun.launchTime), 'second');

      /*if (!lastPassage) {
                // No passages, use start position
                this.nextMarkerName = markerInfo.start.nextMarker;
                this.calculateNextMarkerTime(markerInfo.start.distanceToNext - (speed * this.elapsedSeconds));
            }else {
                //console.log(lastPassage.markerName);
                if (markerInfo[lastPassage.markerName].nextMarker) {
                    this.nextMarkerName = markerInfo[lastPassage.markerName].nextMarker;
                    //console.log(speed * this.elapsedSeconds);
                    console.log(markerInfo[markerInfo[lastPassage.markerName].nextMarker].distanceFromStart);
                    if (speed * this.elapsedSeconds > markerInfo[markerInfo[lastPassage.markerName].nextMarker].distanceFromStart) {
                        //this.pigMarker.setLatLng(markerInfo[markerInfo[lastPassage.markerName].nextMarker].latLng);
                    } else {
                        
                        const line = turfHelpers.lineString(this.pigRun.pigPath.pigLineGenerated);
                        const newLocation = along(line, speed * this.elapsedSeconds, {units: 'meters'});
                        //console.log(newLocation);
                        //this.pigMarker.setLatLng(L.latLng(newLocation.geometry.coordinates[1], newLocation.geometry.coordinates[0]));
                    }
                    this.calculateNextMarkerTime(markerInfo[markerInfo[lastPassage.markerName].nextMarker].distanceFromStart - (speed * this.elapsedSeconds));
                } else {
                    this.calculateNextMarkerTime(null); // null
                }
            }*/
    }
    //this.calculateCompleteTime();
  }

  public calculateCompleteTime() {
    /*console.log('PigProgressMarkers: calculateCompleteTime()');
        if (this.pigRun) {
            const speed = this.pigRun.calculatedSpeed && this.pigRun.calculatedSpeed > 0 ? this.pigRun.calculatedSpeed : this.pigRun.pigPath.expectedSpeed;
            let remainingLength = this.distanceTotal - (speed * this.elapsedSeconds);
            if (this.pigMarker) {
                const currentLocation = turf.point([this.pigMarker.getLatLng().lng, this.pigMarker.getLatLng().lat]);
                const line = turfHelpers.lineString(this.pigRun.pigPath.pigLineGenerated);
                const snapped = turf.nearestPointOnLine(line, currentLocation, {units: 'meters'});
                if (snapped && snapped.properties && snapped.properties.location) {
                    // console.log(this.distanceTotal);
                    // console.log(snapped.properties.location);
                    // console.log(this.distanceTotal - snapped.properties.location);
                    remainingLength = this.distanceTotal - snapped.properties.location;
                }
            }
            //console.log(remainingLength);
            if (remainingLength <= 0) {
                //this.etaComplete = 0;
            } else {
                const secondsRemaining = remainingLength / speed;
                //console.log(secondsRemaining);
                //this.etaComplete = secondsRemaining;
            }
        }*/
  }

  public calculateNextMarkerTime(distanceToNext: number | null) {
    /*console.log('PigProgressMarkers: calculateNextMarkerTime()');
        if (this.pigRun && this.pigMarker) {
            if (!distanceToNext) {
                //this.etaNext = -1;
            } else {
                if (distanceToNext <= 0) {
                    //this.etaNext = 0;
                } else {
                    const speed = this.pigRun.calculatedSpeed && this.pigRun.calculatedSpeed > 0 ? this.pigRun.calculatedSpeed : this.pigRun.pigPath.expectedSpeed;
                    const remainingLength = distanceToNext;
                    //console.log(remainingLength);
                    const secondsRemaining = remainingLength / speed;
                    //console.log(secondsRemaining);
                    //this.etaNext = secondsRemaining;
                }
            }
        }*/
  }

  public calculateMarkerEta(requestedMarkerName: string) {
    console.log('PigProgressMarkers: calculateMarkerEta()');
    if (this.pigRun && this.pigRun.markers && this.pigMarker) {
      const speed =
        this.pigRun.calculatedSpeed && this.pigRun.calculatedSpeed > 0
          ? this.pigRun.calculatedSpeed
          : this.pigRun.pigPath.expectedSpeed;
      const line = turfHelpers.lineString(this.pigRun.pigPath.pigLineGenerated);
      const agmMarker = this.pigRun.markers.find(
        (findAgmMarker: AgmMarker) => findAgmMarker.name === requestedMarkerName
      );
      if (agmMarker) {
        const pigDistance = turf.nearestPointOnLine(
          line,
          turf.point([
            this.pigMarker.getLatLng().lng,
            this.pigMarker.getLatLng().lat,
          ]),
          { units: 'meters' }
        );
        const markerDistance = turf.nearestPointOnLine(
          line,
          turf.point([agmMarker.lon, agmMarker.lat]),
          { units: 'meters' }
        );
        if (
          pigDistance &&
          pigDistance.properties &&
          pigDistance.properties.location &&
          markerDistance &&
          markerDistance.properties &&
          markerDistance.properties.location
        ) {
          const distance =
            markerDistance.properties.location -
            pigDistance.properties.location;
          // calculate and report back to MarkerAction
          this.$store.dispatch('refreshMarkerEta', distance / speed);
        }
      }
    }
  }

  public onShowChart(props: any, type: string) {
    console.log('PigProgressMarkers: showChart()');
    this.$store.dispatch('setPassageDialogSource', 'verify_passage');
    this.$store.dispatch('addUIDToUpdate', props.row.uid);
    const passageIdsToUpdate = [];
    if (props.row.hasOwnProperty('geoId')) {
      if (props.row.geoId != null) {
        passageIdsToUpdate.push(props.row.geoId);
      }
    }
    if (props.row.hasOwnProperty('elfId')) {
      if (props.row.elfId != null) {
        passageIdsToUpdate.push(props.row.elfId);
      }
    }
    if (props.row.hasOwnProperty('magId')) {
      if (props.row.magId != null) {
        passageIdsToUpdate.push(props.row.magId);
      }
    }
    this.$store.dispatch('addPassageIdsToUpdate', passageIdsToUpdate);

    if (type === 'ELF') {
      const passageCopy = { ...props.row };
      passageCopy.passageId = props.row.elfId;
      console.log(passageCopy);
      this.$store.dispatch('passageChartShow', passageCopy);
    }
    if (type === 'GEO') {
      const passageCopy = { ...props.row };
      passageCopy.passageId = props.row.geoId;
      console.log(passageCopy);
      this.$store.dispatch('passageChartShow', passageCopy);
    }
    if (type === 'MAG') {
      const passageCopy = { ...props.row };
      passageCopy.passageId = props.row.magId;
      console.log(passageCopy);
      this.$store.dispatch('passageChartShow', passageCopy);
    }
  }

  public onVerifyFromHistory(passage: Passage) {
    // check if this is a passage from a linked UID
    console.log('onVerifyFromHistory');
    if (
      this.pigRun &&
      this.pigRun.passages &&
      this.pigRun.pigPath &&
      this.pigRun.pigPath.agms
    ) {
      if (this.pigRun.pigPath.agms.indexOf(passage.uid) !== -1) {
        for (const markerPassage of this.pigRun.passages) {
          if (
            markerPassage.passageTimeStamp ===
            passage.passageTimeStamp.slice(0, -3)
          ) {
            console.log('Updating Pig run from history change');
            markerPassage.isVerified = passage.isVerified;
            if (markerPassage.isVerified) {
              this.recalculateSpeedAndEtas(
                markerPassage.markerName,
                markerPassage.passageTimeStamp
              );
            } else {
              // did the last passage get unverified?
              const lastVerifiedPass = this.getLastVerifiedPassage();
              if (lastVerifiedPass) {
                console.log('Reverting to last verified passage');
                this.recalculateSpeedAndEtas(
                  lastVerifiedPass.markerName,
                  lastVerifiedPass.passageTimeStamp
                );
              } else {
                console.log('no passages verified');
                this.pigRun.calculatedSpeed = 0;
                // no passages verified, do a full restore to recover
                //this.restorePigRun();
                this.restorePigRunV3();
              }
            }
            //logger.log(`${this.userInfo} onVerifyFromHistory=>updatePigRun`);
            this.updatePigRun(true);
          }
        }
      }
    }
  }

  public onVerifyPassage(passage: MarkerPassage) {
    console.log('PigProgressMarkers: onVerifyPassage()');
    if (this.pigRun && this.pigRun.passages) {
      if (passage.isVerified == false) {
        // if is verified is false then new isverified will be true
        // We will check if the new passage is meant to be set to verify. This happens when old isverified is false
        // Check 1 does this marker has previously verified passage?
        if (this.CheckifMarkerHasPsg(passage.markerName) == true) {
          // Error
          console.log(
            'PigProgressMarker: This marker has previously verified passage'
          );
          this.VerifyPsg_ErrorMsg =
            'This marker has previously verified passage';
          this.ShowVerifyPsg_ErrorMsg = true;
          return;
        }

        // Check 2: Check to see if this.EditManualPsgTimestamp is before launch time
        if (
          this.CheckifBeforeLaunch(passage.passageTimeStamp + ' GMT') == true
        ) {
          // Error
          console.log(
            'PigProgressMarker: Verify passage Time is before Launch Time'
          );
          this.VerifyPsg_ErrorMsg = 'Entered Time is before Launch Time';
          this.ShowVerifyPsg_ErrorMsg = true;
          return;
        }

        // Check 3: Check to see if this.EditManualPsgTimestamp is in the future
        if (this.CheckifinFuture(passage.passageTimeStamp + ' GMT') == true) {
          // Error
          console.log(
            'PigProgressMarker: Verify passage Time is in the future'
          );
          this.VerifyPsg_ErrorMsg = 'Entered Time is in the Future';
          this.ShowVerifyPsg_ErrorMsg = true;
          return;
        }

        // Check 4: Check to see if this.EditManualPsgTimestamp is a time that occured before any previous verified passages

        if (
          this.CheckifBeforePreviousVerifiedPsg(
            passage.passageTimeStamp + ' GMT',
            this.selectedMarkerName
          ) == true
        ) {
          // Error
          console.log('PigProgressMarker: Manual passage Time is Invalid');
          this.VerifyPsg_ErrorMsg =
            'Entered Time is before of a previous Verified Psg';
          this.ShowVerifyPsg_ErrorMsg = true;
          return;
        }

        // Check 5: Check to see if this.EditManualPsgTimestamp is a time that occured after the next verified passage location
        if (
          this.CheckifAfterNextVerifiedPsg(
            passage.passageTimeStamp + ' GMT',
            this.selectedMarkerName
          ) == true
        ) {
          // Error
          console.log('PigProgressMarker: Manual passage Time is Invalid');
          this.VerifyPsg_ErrorMsg =
            'Entered Time is ahead of a further Verified Psg';
          this.ShowVerifyPsg_ErrorMsg = true;
          return;
        }
      }

      this.ShowVerifyPsg_ErrorMsg = false;
      this.VerifyPsg_ErrorMsg = '';

      this.$store.dispatch('verifyPassage', passage);
      this.updatePigRun(true);
    }
  }

  public onConfirmDeleteManualPassage(passage: MarkerPassage) {
    this.selectedMarkerPassage = passage;
    this.deleteConfirmDialog = true;
  }
  public onConfirmEditManualPassage(passage: MarkerPassage) {
    this.selectedMarkerPassage = passage;
    this.EditManualPsgTimestamp = passage.passageTimeStamp;
    this.editConfirmDialog = true;
  }

  public onShowMissedPassages() {
    console.log('onShowMissedPassages. Enter method.');

    this.showMissedPassages = true;
  }

  public onAddMissedPassage(passage: Passage) {
    console.log('onAddMisedPassage');
    if (this.pigRun && this.pigRun.passages) {
      this.pigRun.passages.push(
        new MarkerPassage(
          this.selectedMarkerName,
          passage.passageTimeStamp, // this passage timestamp has already been sliced to be compatible with pig runs
          passage.uid,
          passage.isVerified,
          passage.passageId,
          passage.passageDetectedFrequency,
          passage.detectionCount,
          passage.packetComplexInformation,
          passage.passageTimeEndOfSnip,
          passage.data,
          passage.elfId,
          passage.geoId,
          passage.magId
        )
      );
      this.$store.dispatch('removeMissedPassage', passage);
      //logger.log(`${this.userInfo} onAddMissedPassage=>updatePigRun`);
      this.updatePigRun(true);
      this.showMissedPassages = false;
    }
  }

  public onDeleteManualPassage() {
    console.log('delete me');
    console.log(this.selectedMarkerPassage);
    if (this.pigRun && this.pigRun.passages && this.selectedMarkerPassage) {
      this.pigRun.passages = this.pigRun.passages.filter(
        (el: any) =>
          el.passageTimeStamp !==
          (this.selectedMarkerPassage as MarkerPassage).passageTimeStamp
      );
      // did the last passage get unverified?
      const lastVerifiedPass = this.getLastVerifiedPassage();
      if (lastVerifiedPass) {
        console.log('Reverting to last verified passage');
        this.recalculateSpeedAndEtas(
          lastVerifiedPass.markerName,
          lastVerifiedPass.passageTimeStamp
        );
      } else {
        console.log('no passages verified');
        this.pigRun.calculatedSpeed = 0;
        // no passages verified, do a full restore to recover
        //this.restorePigRun();
        this.restorePigRunV3();
      }
      //logger.log(`${this.userInfo} onDeleteManualPassage=>updatePigRun`);
      this.updatePigRun(true);
    }
  }

  public getLastVerifiedPassage(): MarkerPassage | null {
    if (this.pigRun && this.pigRun.markers && this.pigRun.passages) {
      const sortedPassages = this.pigRun.passages.sort(
        (a: MarkerPassage, b: MarkerPassage) => {
          const timeA = a.passageTimeStamp;
          const timeB = b.passageTimeStamp;
          let comparison = 0;
          if (timeA > timeB) {
            comparison = 1;
          } else if (timeA < timeB) {
            comparison = -1;
          }
          return comparison;
        }
      );
      for (let i = sortedPassages.length - 1; i >= 0; i--) {
        if (sortedPassages[i].isVerified) {
          const markerOrder = this.pigRun.markers.map((m: AgmMarker) => m.name);
          this.furthestVerifiedPosition = markerOrder.indexOf(
            sortedPassages[i].markerName
          );
          return sortedPassages[i];
        }
      }
    }
    this.furthestVerifiedPosition = -1;
    return null;
  }

  public async onViewPassages(data: any) {
    // Machine Learning - Added service calls to populate applicableAlgorithmIds which is used to filter the machine learning results.
    //data[0] = runid
    let queriedToolId = -1;
    await PigRunService.getPigRunToolForRunId(data[0])
      .then((response) => {
        console.log(
          `PigProgressMarkers.vue PigRunService.getPigRunToolForRunId(). Get all PigRunTool rows for runId = ${data[0]}.`
        );
        if (response.data.length >= 1) {
          queriedToolId = response.data[0].toolId;
        }
      })
      .catch((exception) => {
        console.error(exception);
      });

    console.log(
      `PigProgressMarkers.vue PigRunService.getPigRunToolForRunId(). First toolId = ${queriedToolId}`
    );

    // If we have a valid toolId queried from the database, we get all of the applicable COnfidences
    if (queriedToolId != -1) {
      await PigRunService.getMachineLearningAlgorithmToolForToolId(
        queriedToolId
      )
        .then((response) => {
          console.log(
            `PigProgressMarkers.vue PigRunService.getMachineLearningAlgorithmToolForToolId(). Get all MachineLearningAlgorithmTool rows for toolId = ${queriedToolId}.`
          );
          for (let i = 0; i < response.data.length; i++) {
            this.applicableAlgorithmIds[i] =
              response.data[i].machineLearningAlgorithmId;
          }
        })
        .catch((exception) => {
          console.error(exception);
        });
    }

    //data[0] = runid
    //data[1] = markername
    var requested_runid = data[0];
    var markername = data[1];

    // Remove all Error Messages
    this.showErrorMsg = false;
    this.showErrorMsg2 = false;
    this.EditManual_ErrorMsg = false;
    this.ShowVerifyPsg_ErrorMsg = false;
    this.ManualPsg_ErrorMsg = '';
    this.VerifyPsg_ErrorMsg = '';

    if (this.pigRun) {
      if (this.pigRun.runId == requested_runid && this.pigRun.markers) {
        console.log('PigProgressMarkers: onViewPassages()');
        const selectedMarker = this.pigRun.markers.find((marker: AgmMarker) => {
          return marker.name === markername;
        });
        this.$store.dispatch('checkMissingAndPartialPassages', selectedMarker);
        this.passagesDialog = true;
        this.selectedMarkerName = markername;
      }
    }
  }

  public onAddPassage(runId: number | null, markerName: string) {
    if (this.pigRun) {
      if (runId == this.pigRun.runId) {
        console.log('PigProgressMarkers: onAddPassage()');
        this.addPassageDialog = true;
        this.passageMarker = markerName;
        this.passageTimestamp = dayjs
          .default()
          .utc()
          .format('YYYY-MM-DD HH:mm:ss');
      }
    }
  }

  public closeManualPsg() {
    this.addPassageDialog = false;
    this.showErrorMsg = false;
    this.showErrorMsg2 = false;
    this.EditManual_ErrorMsg = false;
    this.ShowVerifyPsg_ErrorMsg = false;
    this.ManualPsg_ErrorMsg = '';
    this.VerifyPsg_ErrorMsg = '';
  }

  public closeViewPsgs() {
    this.addPassageDialog = false;
    this.showErrorMsg = false;
    this.showErrorMsg2 = false;
    this.EditManual_ErrorMsg = false;
    this.ShowVerifyPsg_ErrorMsg = false;
    this.ManualPsg_ErrorMsg = '';
    this.VerifyPsg_ErrorMsg = '';
  }

  public onAddPassageInfo() {
    // We need to verify this.passageTimestamp is properly formatted
    debugger;
    console.log(this.passageTimestamp);
    var psgtime = new Date(this.passageTimestamp + ' GMT');

    var isIos = /iPad|iPhone|iPod/i.test(navigator.userAgent);

    if (isIos == true) {
      var acttime, hour, min, sec, year, month, day, milisec;
      var TestDate: Date;
      acttime = this.passageTimestamp;
      //UTC Time//
      year = acttime[0] + acttime[1] + acttime[2] + acttime[3];
      month = acttime[5] + acttime[6];
      day = acttime[8] + acttime[9];
      hour = acttime[11] + acttime[12];
      min = acttime[14] + acttime[15];
      sec = acttime[17] + acttime[18];
      milisec = '000';
      //UTC Time//
      psgtime = new Date();
      psgtime.setUTCFullYear(parseInt(year));
      psgtime.setUTCMonth(parseInt(month) - 1);
      psgtime.setUTCDate(parseInt(day));
      psgtime.setUTCHours(parseInt(hour));
      psgtime.setUTCMinutes(parseInt(min));
      psgtime.setUTCSeconds(parseInt(sec));
      psgtime.setUTCMilliseconds(0);
      if (isNaN(psgtime.getTime())) {
        this.ManualPsg_ErrorMsg =
          'Please enter valid Passage Time {YYYY-MM-DD HH:MM:SS}';
        this.showErrorMsg = true;
        this.addPassageDialog = true;
        return;
      }
    } else {
      if (isNaN(psgtime.getTime())) {
        this.ManualPsg_ErrorMsg =
          'Please enter valid Passage Time {YYYY-MM-DD HH:MM:SS}';
        this.showErrorMsg = true;
        this.addPassageDialog = true;
        return;
      }
    }

    // Check 1: Check to see if this marker has previously verified passage

    if (this.CheckifMarkerHasPsg(this.passageMarker) == true) {
      // Error
      console.log(
        'PigProgressMarker: This marker has previously verified passage'
      );
      this.ManualPsg_ErrorMsg = 'This marker has previously verified passage';
      this.showErrorMsg = true;
      return;
    }

    // Check 2: Check to see if this.passageTimestamp is before launch time
    if (this.CheckifBeforeLaunch(this.passageTimestamp + ' GMT') == true) {
      // Error
      console.log(
        'PigProgressMarker: Manual passage Time is before Launch Time'
      );
      this.ManualPsg_ErrorMsg = 'Manual passage Time is before Launch Time';
      this.showErrorMsg = true;
      return;
    }

    // Check 3: Check to see if this.passageTimestamp is in the future
    if (this.CheckifinFuture(this.passageTimestamp + ' GMT') == true) {
      // Error
      console.log('PigProgressMarker: Manual passage Time is in the future');
      this.ManualPsg_ErrorMsg = 'Entered Time is in the Future';
      this.showErrorMsg = true;
      return;
    }

    // Check 4: Check to see if this.passageTimestamp is a time that occured after the next verified passage location

    if (
      this.CheckifBeforePreviousVerifiedPsg(
        this.passageTimestamp + ' GMT',
        this.passageMarker
      ) == true
    ) {
      // Error
      console.log('PigProgressMarker: Manual passage Time is Invalid');
      this.ManualPsg_ErrorMsg =
        'Entered Time is before or equals to a previously verified psg';
      this.showErrorMsg = true;
      return;
    }

    // Check 5: Check to see if this.passageTimestamp is a time that occured after the next verified passage location
    if (
      this.CheckifAfterNextVerifiedPsg(
        this.passageTimestamp + ' GMT',
        this.passageMarker
      ) == true
    ) {
      // Error
      console.log(
        'PigProgressMarker: Entered Time is ahead of a further Verified Psg'
      );
      this.ManualPsg_ErrorMsg =
        'Entered Time is ahead of a further Verified Psg';
      this.showErrorMsg = true;
      return;
    }

    // Check 6: Check to see if this.passageTimestamp is a time that occured after the next verified passage location

    if (this.CheckifVerifiedExist() == true) {
      // Error
      console.log(
        'PigProgressMarker: Verified passage Already Exist at this location'
      );
      this.ManualPsg_ErrorMsg =
        'Verified passage Already Exist at this location';
      this.showErrorMsg = true;
      return;
    }

    this.addPassageDialog = false;
    this.showErrorMsg = false;

    console.log('PigProgressMarkers: onAddPassageInfo()');
    console.log(
      'PigProgressMarkers. this.passageMarker = [' + this.passageMarker + [']']
    );

    if (this.passageMarker && this.pigRun) {
      const triggerManualAlertPayload: any = {
        markerName: this.passageMarker,
        runId: this.pigRun.runId,
      };

      this.$store.dispatch('triggerManualAlert', triggerManualAlertPayload);
    }

    if (this.passageMarker) {
      // marker passages are verified
      this.addMarkerPassage(this.passageMarker, this.passageTimestamp, true);
    }
  }

  public onZoomToPig() {
    console.log('PigProgressMarkers: onZoomToPig()');
    if (this.pigRun && this.pigMarker) {
      this.map.flyTo(this.pigMarker.getLatLng(), 16);
    }
  }

  public onNotifications() {
    console.log('PigProgressMarkers: onNotifications()');
    this.changeNotificationsDialog = true;
  }

  public onEndRun() {
    console.log('PigProgressMarkers: onEndRun()');
    if (this.ReceiveErrorMsg != '') {
      return;
    }
    if (this.pigRun) {
      if (!this.pigRun.recieveTime) {
        this.pigRun.recieveTime = new Date(this.ReceiveTimestamp + ' GMT');
        var isIos = /iPad|iPhone|iPod/i.test(navigator.userAgent);
        if (isIos == true) {
          var acttime, hour, min, sec, year, month, day, milisec;

          acttime = this.ReceiveTimestamp;
          //UTC Time//
          year = acttime[0] + acttime[1] + acttime[2] + acttime[3];
          month = acttime[5] + acttime[6];
          day = acttime[8] + acttime[9];
          hour = acttime[11] + acttime[12];
          min = acttime[14] + acttime[15];
          sec = acttime[17] + acttime[18];
          milisec = '000';
          //UTC Time//
          var tempDate = new Date();
          this.pigRun.recieveTime = new Date();
          tempDate.setUTCFullYear(parseInt(year));
          tempDate.setUTCMonth(parseInt(month) - 1);
          tempDate.setUTCDate(parseInt(day));
          tempDate.setUTCHours(parseInt(hour));
          tempDate.setUTCMinutes(parseInt(min));
          tempDate.setUTCSeconds(parseInt(sec));
          tempDate.setUTCMilliseconds(0);
          this.pigRun.recieveTime = tempDate;
        }

        if (this.tempMarker) {
          this.tempMarker.removeFrom(this.map);
          this.tempMarker = null;
        }
      }
      this.$store.dispatch('endPigRun', this.pigRun);
    }
  }

  public onToggleDialogSize() {
    console.log('PigProgressMarkers: onToggleDialogSize()');
    this.minimizeDialog = !this.minimizeDialog;
  }

  public onCancelConfirm() {
    console.log('PigProgressMarkers: onCancelConfirm()');
    this.cancelConfirmDialog = true;
  }

  public async onCancelRun() {
    console.log('PigProgressMarkers: onCancelRun()');
    if (this.pigRun) {
      if (this.tempMarker) {
        this.tempMarker.removeFrom(this.map);
        this.tempMarker = null;
      }
      let runId = this.pigRun.runId;
      let deleteAllPigRunToolsForPigRunRequest: DeleteAllPigRunToolsForPigRunRequest =
        new DeleteAllPigRunToolsForPigRunRequest(runId);
      await PigRunService.deleteAllPigRunToolsForPigRun(
        deleteAllPigRunToolsForPigRunRequest
      )
        .then((response) => {
          console.log(
            `PigProgressMarkers.vue PigRunService.deleteAllPigRunToolsForPigRun(). Removed all PigRunTool records with runId ${runId}.`
          );
        })
        .catch((exception) => {
          console.error(exception);
          return;
        });
      this.$store.dispatch('cancelPigRun', this.pigRun.runId);
    }
  }

  public updatePigRun(ignoreNextUpdateMessage = true) {
    console.log('PigProgressMarkers: updatePigRun()');
    // TODO viewer role should never push an update
    if (this.pigRun && this.isOnline) {
      console.log(this.pigRun);
      const passageCount = this.pigRun.passages
        ? this.pigRun.passages.length
        : 0;
      //logger.log(`${this.userInfo} updatePigRun ${this.pigRun.runId} with ${passageCount} passages`);
      this.$store.dispatch('ignoreNextPigRunUpdate', ignoreNextUpdateMessage);
      this.$store.dispatch('upsertPigRun', this.pigRun);
      this.idleSeconds = 1;
    } else {
      //logger.log(`${this.userInfo} updatePigRun IGNORED`);
    }
  }

  public updatePigRunNotifications(ignoreNextUpdateMessage = true) {
    console.log('PigProgressMarkers: updatePigRun()');
    // TODO viewer role should never push an update
    if (this.pigRun) {
      console.log(this.pigRun);
      this.$store.dispatch('ignoreNextPigRunUpdate', ignoreNextUpdateMessage);
      this.$store.dispatch('updatePigRunNotifications', this.pigRun);
      this.idleSeconds = 1;
    }
  }

  public updatePigRunAgms(ignoreNextUpdateMessage = true) {
    console.log('PigProgressMarkers: updatePigRun()');
    // TODO viewer role should never push an update
    if (this.pigRun) {
      console.log(this.pigRun);
      this.$store.dispatch('ignoreNextPigRunUpdate', ignoreNextUpdateMessage);
      this.$store.dispatch('updatePigRunAgms', this.pigRun);
      this.idleSeconds = 1;
    }
  }

  public beforeDestroy() {
    console.log('PigProgressMarkers: beforeDestroy()');
    // This will restore the color of any linked devices that got changed for the pig run
    if (this.pigRun) {
      for (const device of this.devices) {
        if (device && device.lat !== 0 && device.lon !== 0) {
          this.map.eachLayer((layer: any) => {
            if (layer.hasOwnProperty('_leaflet_id')) {
              if (this.leafletLayers[layer._leaflet_id] === device.uid) {
                if (device.isIridium) {
                  if (device.isConnected) {
                    layer.setIcon(MapCommon.agmIconOnlineIridium);
                  } else {
                    layer.setIcon(MapCommon.agmIconOfflineIridium);
                  }
                } else {
                  if (device.isConnected) {
                    layer.setIcon(MapCommon.agmIconOnline);
                  } else {
                    layer.setIcon(MapCommon.agmIconOffline);
                  }
                }
              }
            }
          });
        }
      }
    }
    if (this.pigRunLayers) {
      this.pigRunLayers.removeFrom(this.map);
      this.layerControl.removeLayer(this.pigRunLayers);
    }
    for (let i = 0; i < this.agmMapMarkers.length; i++) {
      this.agmMapMarkers[i].removeFrom(this.map);
    }
    this.hideAutoLinkRadius();
    this.agmMapMarkers = [];

    var h = [];
    h.push(this.pigRun);
    h.push(null);
    this.$store.dispatch('setMarkerLayers', h);
    //this.$store.dispatch('setMarkerLayers', {});
    this.unsubscribe();
    this.stopWatching();
    EventBus.$off('passage-verified', this.handlePassageVerified);
    EventBus.$off('switch-passage', this.handleSwitchPassage);
    clearInterval(this.timerId);
    clearInterval(this.idleTimerId);
  }

  handlePassageVerified({ passage }: IPassageVerified) {
    console.log('PigProgressMarkers.vue. passage-verified event received.');
    const { passageId } = passage;

    let psg = null;
    this.pigRun?.passages?.forEach((p: MarkerPassage) => {
      console.log('markus', this.pigRun?.passages);
      if (
        p.elfId === passageId ||
        p.geoId === passageId ||
        p.magId === passageId
      ) {
        psg = p;
      }
    });

    if (psg == null) {
      console.log(
        'PigProgressMarkers: handlePassageVerified() - passage not found'
      );
      return;
    }

    this.onVerifyPassage(psg);
  }

  handleSwitchPassage({
    passage,
    direction,
    type,
  }: {
    passage: Passage;
    direction: 'next' | 'previous';
    type: string;
  }) {
    console.log('PigProgressMarkers.vue. switch-passage event received.');

    this.direction = direction;
    const markerPsgs: MarkerPassage[] = [...(this.pigRun?.passages || [])];
    if (!markerPsgs.length) {
      console.log('PigProgressMarkers: handleSwitchPassage() - no passages');
      return;
    }

    const currentIndex = markerPsgs.findIndex(
      (psg: MarkerPassage) =>
        psg.elfId === passage.passageId ||
        psg.geoId === passage.passageId ||
        psg.magId === passage.passageId
    );

    if (currentIndex === -1) {
      console.log(
        'PigProgressMarkers: handleSwitchPassage() - passage not found'
      );
      return;
    }

    const inc = direction === 'next' ? 1 : -1;

    let nextIndex = -1;
    for (
      let index = (currentIndex + inc + markerPsgs.length) % markerPsgs.length;
      index != currentIndex;
      index = (index + inc + markerPsgs.length) % markerPsgs.length
    ) {
      if (markerPsgs[index].markerName === this.selectedMarkerName) {
        nextIndex = index;
      }
    }

    if (nextIndex === -1) {
      console.log(
        'PigProgressMarkers: handleSwitchPassage() - next passage not found'
      );
      return;
    }

    this.onShowChart({ row: markerPsgs[nextIndex] }, type);
  }

  public watchOnlineStatus() {
    window.addEventListener('offline', this.setOffline);
    window.addEventListener('online', this.setOnline);
    document.addEventListener('visibilitychange', this.visibilityChange, false);
    document.addEventListener('resume', this.resumeHandler);
    document.addEventListener('freeze', this.freezeHandler);
    document.addEventListener('pageshow', this.pageshowHandler);
  }

  public onRefreshAction(payload: number) {
    console.log('PigProgressMarkers.vue. onRefreshAction. Enter method.');

    var tempMarkerState;

    if (this.pigRun?.markers != undefined) {
      tempMarkerState = this.pigRun?.markers[0].state;
    }

    console.log(
      'PigProgressMarkers.vue. onRefreshAction. Marker[0].state = [' +
        tempMarkerState +
        ']'
    );

    if (this.pigRun && this.isOnline === true) {
      console.log('PigProgressMarkers.vue. onRefreshAction. Section 1 entry.');

      if (payload === this.pigRun.runId) {
        if (!this.ignoreNextPigRunUpdate) {
          console.log(
            'PigProgressMarkers.vue. onRefreshAction. Section 2 entry. RunId = [' +
              payload +
              ']'
          );
          console.log(
            'PigProgressMarkers.vue. onRefreshAction. Section 2 entry. Before dispatch action = [restorePigRun]'
          );

          this.$store.dispatch('restorePigRun', payload);

          console.log(
            'PigProgressMarkers.vue. onRefreshAction. Section 2 entry. After dispatch action = [restorePigRun]'
          );
        }

        this.$store.dispatch('ignoreNextPigRunUpdate', false);
      }
    }

    //        else
    //        {
    //            //logger.log(`${this.userInfo} onRefreshAction - IGNORE UPDATE`);
    //            console.log('onRefreshAction - IGNORE UPDATE');
    //        }

    if (this.pigRun?.markers != undefined) {
      tempMarkerState = this.pigRun?.markers[0].state;
    }

    console.log(
      'PigProgressMarkers.vue. onRefreshAction. Marker[0].state = [' +
        tempMarkerState +
        ']'
    );
    console.log('PigProgressMarkers.vue. onRefreshAction. Exit method.');
  }

  public onupdateLocAction(payload: any) {
    if (this.pigRun) {
      var RunId, Lat, Lon, speed;
      var locTime: Date;
      var etatonext: Date;
      var etatonexttrack: Date;
      var etatoend: Date;
      var serverTime: Date;
      RunId = payload[0];
      Lat = payload[1];
      Lon = payload[2];
      speed = payload[7];

      if (this.pigRun.runId != RunId) {
        return;
      }

      if (payload[5] == 'paused') {
        this.isRunPaused = true;
        if (this.pigMarker) {
          this.pigMarker.setLatLng(L.latLng(Lat, Lon));
        }
        return;
      }
      this.isRunPaused = false;

      if (speed != 0) {
        this.pigRun.calculatedSpeed = speed;
      }

      serverTime = new Date(payload[3]);
      locTime = new Date(
        Date.UTC(
          serverTime.getFullYear(),
          serverTime.getMonth(),
          serverTime.getDate(),
          serverTime.getHours(),
          serverTime.getMinutes(),
          serverTime.getSeconds(),
          serverTime.getMilliseconds()
        )
      );

      if (payload[4] != 'passed') {
        var locetatonext: Date;
        etatonext = new Date(payload[4]);

        locetatonext = new Date(
          Date.UTC(
            etatonext.getFullYear(),
            etatonext.getMonth(),
            etatonext.getDate(),
            etatonext.getHours(),
            etatonext.getMinutes(),
            etatonext.getSeconds(),
            etatonext.getMilliseconds()
          )
        );
      }
      if (payload[5] == 'passed') {
        this.etaNext = 0;
      } else if (payload[5] == 'none') {
        this.etaNext = -1;
      } else {
        var locetatonexttrack: Date;
        etatonexttrack = new Date(payload[5]);
        locetatonexttrack = new Date(
          Date.UTC(
            etatonexttrack.getFullYear(),
            etatonexttrack.getMonth(),
            etatonexttrack.getDate(),
            etatonexttrack.getHours(),
            etatonexttrack.getMinutes(),
            etatonexttrack.getSeconds(),
            etatonexttrack.getMilliseconds()
          )
        );
        this.etaNext = (locetatonexttrack.getTime() - locTime.getTime()) / 1000;
      }

      if (payload[6] == 'passed') {
        this.etaComplete = 0;
        this.etaNext = -1;
      } else if (payload[6] == '') {
        // do not update this.etaComplete
      } else {
        var locetatoend: Date;
        etatoend = new Date(payload[6]);
        //console.log(etatoend);
        locetatoend = new Date(
          Date.UTC(
            etatoend.getFullYear(),
            etatoend.getMonth(),
            etatoend.getDate(),
            etatoend.getHours(),
            etatoend.getMinutes(),
            etatoend.getSeconds(),
            etatoend.getMilliseconds()
          )
        );
        this.complete_time =
          locetatoend.getUTCHours().toString() +
          ':' +
          locetatoend.getUTCMinutes().toString() +
          ':' +
          locetatoend.getUTCSeconds().toString();
        this.etaComplete = (locetatoend.getTime() - locTime.getTime()) / 1000;
        //console.log(this.complete_time);
        //console.log(this.etaComplete);
      }

      // TDO use run id to know which marker to update when we have multiple runs happening at
      // the same time.
      if (this.pigMarker) {
        this.pigMarker.setLatLng(L.latLng(Lat, Lon));
      }
    }
  }

  public onupdateLocActionV2(payload: any) {
    if (this.pigRun) {
      var RunId, Lat, Lon, speed;
      var locTime: Date;
      var etatonext: Date;
      var etatonexttrack: Date;
      var etatoend: Date;
      var serverTime: Date;
      var etaTo_Next;
      var etaTo_Complete;

      RunId = payload[0];
      Lat = payload[1];
      Lon = payload[2];
      speed = payload[7];
      etaTo_Next = payload[5];
      etaTo_Complete = payload[6];

      // If etaTo_Next = -1 => paused
      // If etaTo_Next = -2 => passed
      // If etaTo_Next = -3 => none
      // If etaTo_Next>=0 => equals to this.etaNext

      // If etaTo_Complete = -1 => ""
      // If etaTo_Complete = -2 => passed
      // If etaTo_Complete>=0 => equals to this.etaComplete

      if (this.pigRun.runId != RunId) {
        return;
      }

      if (etaTo_Next == -1) {
        this.isRunPaused = true;
        if (this.pigMarker) {
          this.pigMarker.setLatLng(L.latLng(Lat, Lon));
        }
        return;
      } else if (etaTo_Next == -2) {
        this.etaNext = 0;
        this.isRunPaused = false;
      } else if (etaTo_Next == -3) {
        this.etaNext = -1;
        this.isRunPaused = false;
      } else {
        this.etaNext = etaTo_Next;
        this.isRunPaused = false;
      }

      if (speed != 0) {
        this.pigRun.calculatedSpeed = speed;
      }

      serverTime = new Date(payload[3]);
      locTime = new Date(
        Date.UTC(
          serverTime.getFullYear(),
          serverTime.getMonth(),
          serverTime.getDate(),
          serverTime.getHours(),
          serverTime.getMinutes(),
          serverTime.getSeconds(),
          serverTime.getMilliseconds()
        )
      );

      if (payload[4] != 'passed') {
        var locetatonext: Date;
        etatonext = new Date(payload[4]);
        locetatonext = new Date(
          Date.UTC(
            etatonext.getFullYear(),
            etatonext.getMonth(),
            etatonext.getDate(),
            etatonext.getHours(),
            etatonext.getMinutes(),
            etatonext.getSeconds(),
            etatonext.getMilliseconds()
          )
        );
      }

      if (etaTo_Complete == -1) {
        // do not update this.etaComplete
      } else if (etaTo_Complete == -2) {
        this.etaComplete = 0;
        this.etaNext = -1;
      } else {
        this.etaComplete = etaTo_Complete;
      }

      if (this.pigMarker) {
        this.pigMarker.setLatLng(L.latLng(Lat, Lon));
      }
    }
  }

  public onPassageRecieved(payload: any) {
    if (this.pigRun && this.pigRun.markers && this.isOnline) {
      if (this.linkedUids.indexOf(payload.uid) !== -1) {
        // linked UID always recorded as part of the pig run
        console.log('Pig Passage at UID=' + payload.uid);
        //console.log(payload);
        const passageMarker = this.pigRun.markers.find(
          (agmMarker: AgmMarker) => agmMarker.linkedUid === payload.uid
        );
        if (passageMarker) {
          this.handleIncommingPassage(payload, passageMarker);
        }
      } else {
        // passage may also be auto linked
        this.checkAutoLinked(payload);
      }
    } else {
      console.log('onPassageRecieved - IGNORE PASSAGE');
      //logger.log(`${this.userInfo} onPassageRecieved - IGNORE PASSAGE`);
    }
  }

  public setOffline() {
    console.log('Pig Run: Offline');
    clearTimeout(this.onlineTimeout);
    this.isOnline = false;
  }

  public setOnline() {
    console.log('Pig Run: Online');
    this.onlineTimeout = setTimeout(() => {
      console.log('Pig Run: setOnline');
      if (!this.isOnline && this.pigRun) {
        this.idleSeconds = 1;
        this.$store.dispatch('getDevices');
        this.$store.dispatch('restorePigRun', this.pigRun.runId).then(() => {
          this.isOnline = true;
        });
      }
    }, 5000);
  }

  public resumeHandler() {
    console.log('RESUME FROZEN PAGE');
    //logger.log(`${this.userInfo} RESUME FROZEN PAGE`);
    this.isOnline = false;
  }

  public freezeHandler() {
    console.log('FREEZE PAGE');
    //logger.log(`${this.userInfo} FREEZE PAGE`);
    this.isOnline = false;
  }

  public pageshowHandler() {
    // this one is never hit? in freeze/resume
    console.log('PAGE SHOW EVENT');
    //logger.log(`${this.userInfo} PAGE SHOW EVENT`);
    this.isOnline = false;
    this.setOnline();
  }

  public visibilityChange() {
    console.log('Pig Run: VisibilityChange Event');
    let hidden = '';
    let visibilityChange = '';
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
      // @ts-ignore
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
      // @ts-ignore
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }
    if (document.hidden) {
      console.log('HIDDEN/MINIMIZED');
      //logger.log(`${this.userInfo} HIDDEN/MINIMIZED`);
      this.setOffline();
    } else {
      //logger.log(`${this.userInfo} RESTORE FROM VISIBILITY CHANGE`);
      console.log('RESTORE FROM VISIBILITY CHANGE');
      this.setOnline();
    }
  }

  public startIdleTimer() {
    this.idleTimerId = setInterval(() => {
      this.idleSeconds++;
      if (this.idleSeconds % 300 === 0 && this.isOnline) {
        console.log('RESTORE FROM IDLE');
        //logger.log(`${this.userInfo} RESTORE FROM IDLE`);
        if (this.pigRun) {
          this.$store.dispatch('restorePigRun', this.pigRun.runId);
          this.idleSeconds = 1;
        }
      }
    }, 1000) as any;
  }

  public stopWatching() {
    window.removeEventListener('offline', this.setOffline, false);
    window.removeEventListener('online', this.setOnline, false);
    document.removeEventListener(
      'visibilitychange',
      this.visibilityChange,
      false
    );
    document.removeEventListener('resume', this.resumeHandler, false);
    document.removeEventListener('freeze', this.freezeHandler, false);
    document.removeEventListener('pageshow', this.pageshowHandler, false);
  }
  public startup = true;
  public mounted() {
    this.position;
    this.pigRun;
    console.log('PigProgressMarkers: mounted()');
    this.watchOnlineStatus();
    this.startIdleTimer();
    this.setupPigRun();
    EventBus.$on('passage-verified', this.handlePassageVerified);
    EventBus.$on('switch-passage', this.handleSwitchPassage);
  }

  public Launch_at_TimeDialog = false;
  public ErrorMsg = false;
  public LaunchTimestamp: any = '2021-06-27 01:02:03';

  public ReceiveTimestamp: any = '2021-06-27 01:02:03';

  public onEditLaunchTime() {
    console.log('PigProgressMarkers: onEditLaunchTime()');
    if (this.pigRun) {
      // Enable Edit PigRun Launch Time Dialog
      this.Launch_at_TimeDialog = true;
      //this.LaunchTimestamp = this.pigRun.launchTime;// Displays the time right now in UTC

      console.log(this.pigRun.launchTime?.toString());
      var index = 0;
      var st: string;

      if (this.pigRun.launchTime) {
        st = this.pigRun.launchTime.toString();
        var year, month, day, hour, min, sec;
        year = this.pigRun.launchTime.getUTCFullYear();
        month = this.pigRun.launchTime.getUTCMonth() + 1;
        day = this.pigRun.launchTime.getUTCDate();
        hour = this.pigRun.launchTime.getUTCHours();
        min = this.pigRun.launchTime.getUTCMinutes();
        sec = this.pigRun.launchTime.getUTCSeconds();
        //this.LaunchTimestamp = year.toString() + "-"; + month.toString() + "-" + day.toString() + " " + hour.toString() + ":" + min.toString() + ":" + sec.toString();

        this.LaunchTimestamp = year.toString() + '-';
        if (month < 10) {
          this.LaunchTimestamp = this.LaunchTimestamp + '0';
        }
        this.LaunchTimestamp = this.LaunchTimestamp + month.toString() + '-';

        if (day < 10) {
          this.LaunchTimestamp = this.LaunchTimestamp + '0';
        }
        this.LaunchTimestamp = this.LaunchTimestamp + day.toString() + ' ';

        if (hour < 10) {
          this.LaunchTimestamp = this.LaunchTimestamp + '0';
        }
        this.LaunchTimestamp = this.LaunchTimestamp + hour.toString() + ':';

        if (min < 10) {
          this.LaunchTimestamp = this.LaunchTimestamp + '0';
        }
        this.LaunchTimestamp = this.LaunchTimestamp + min.toString() + ':';

        if (sec < 10) {
          this.LaunchTimestamp = this.LaunchTimestamp + '0';
        }
        this.LaunchTimestamp = this.LaunchTimestamp + sec.toString();
      }
    }
  }

  public EditManualPsgTimestamp: any = '2021-06-27 01:02:03';
  public onEditManualPsg() {
    console.log('PigProgressMarker: onEditManualPsg');
    var psgtime_GMT = this.EditManualPsgTimestamp + ' GMT';
    var psgtime = new Date(psgtime_GMT);
    console.log(
      'PigProgressMarker: New Manual Passage Time = ' +
        this.EditManualPsgTimestamp
    );
    var isIos = /iPad|iPhone|iPod/i.test(navigator.userAgent);

    if (isIos == true) {
      var acttime, hour, min, sec, year, month, day, milisec;
      acttime = this.EditManualPsgTimestamp;
      //UTC Time//
      year = acttime[0] + acttime[1] + acttime[2] + acttime[3];
      month = acttime[5] + acttime[6];
      day = acttime[8] + acttime[9];
      hour = acttime[11] + acttime[12];
      min = acttime[14] + acttime[15];
      sec = acttime[17] + acttime[18];
      milisec = '000';
      //UTC Time//
      psgtime = new Date();
      psgtime.setUTCFullYear(parseInt(year));
      psgtime.setUTCMonth(parseInt(month) - 1);
      psgtime.setUTCDate(parseInt(day));
      psgtime.setUTCHours(parseInt(hour));
      psgtime.setUTCMinutes(parseInt(min));
      psgtime.setUTCSeconds(parseInt(sec));
      psgtime.setUTCMilliseconds(0);
      if (isNaN(psgtime.getTime())) {
        this.ManualPsg_ErrorMsg =
          'Please enter valid Passage Time {YYYY-MM-DD HH:MM:SS}';
        this.showErrorMsg = true;
        this.addPassageDialog = true;
        return;
      }
    } else {
      if (isNaN(psgtime.getTime())) {
        //InValid Date
        this.ManualPsg_ErrorMsg = 'Detected invalid characters';
        this.EditManual_ErrorMsg = true;
        return;
      }
    }

    if (this.selectedMarkerPassage) {
      // marker passages are verified
      this.addEditMarkerPassage(this.selectedMarkerPassage);
    }
  }

  public onAddLaunchTime() {
    debugger;
    if (this.pigRun) {
      // convert this.LaunchTimestamp;  to date
      var hour, min, sec, year, month, day, milisec;
      var LaunchDate: Date;
      var NowDate: Date;
      var acttime;
      acttime = this.LaunchTimestamp;
      year = acttime[0] + acttime[1] + acttime[2] + acttime[3];
      month = acttime[5] + acttime[6];
      day = acttime[8] + acttime[9];
      hour = acttime[11] + acttime[12];
      min = acttime[14] + acttime[15];
      sec = acttime[17] + acttime[18];
      milisec = '000';
      //UTC Time//
      LaunchDate = new Date();
      LaunchDate.setUTCFullYear(parseInt(year));
      LaunchDate.setUTCMonth(parseInt(month) - 1);
      LaunchDate.setUTCDate(parseInt(day));
      LaunchDate.setUTCHours(parseInt(hour));
      LaunchDate.setUTCMinutes(parseInt(min));
      LaunchDate.setUTCSeconds(parseInt(sec));
      LaunchDate.setUTCMilliseconds(0);

      // Need to check if time is in future we should not allow it.
      // If time in future we should report to user as error.
      NowDate = new Date();
      var diff;

      diff = NowDate.getTime() - LaunchDate.getTime();
      if (diff < 0) {
        // Error
        this.ErrorMsg = true;
        this.Launch_at_TimeDialog = true;
      } else {
        this.pigRun.launchTime = LaunchDate;
        this.elapsedSeconds = dayjs
          .default()
          .diff(dayjs.default(this.pigRun.launchTime), 'second');

        // Need to dispatch an update to PigRun so that everyone is aware.
        this.$store.dispatch('upsertPigRun', this.pigRun);
        this.Launch_at_TimeDialog = false;
        this.ErrorMsg = false;
      }
    }
  }

  public onEditPig() {
    // We want to edit the position of the pig.
    // Allow the pig icon to become editable or create new icon
    // register for when drag is over to get coordinates
    // When the user finish editing the pig icon location Dialog box will open
    // User will have to confirm
    if (this.pigRun) {
      if (this.tempMarker != null) {
        this.tempMarker.remove();
      }
      this.tempMarker = L.marker(this.pigRun.pigPath.launchSiteToLatLng(), {
        draggable: true,
        icon: PigRunCommon.pigIcon_2,
        zIndexOffset: -5,
      });
      this.tempMarker.on('dragend', () => {
        if (this.tempMarker && this.tempMarker.dragging) {
          this.tempMarker.dragging.disable();
          this.tempMarker.remove();
          this.FigureStuff();
        }
      });
      this.tempMarker.addTo(this.map);
    }
  }

  public FigureStuff() {
    if (
      this.pigRun &&
      this.pigRun.pigPath.pigLineGenerated &&
      this.tempMarker &&
      this.pigRun.markers
    ) {
      // Get time
      var pt = turf.point([
        this.tempMarker.getLatLng().lng,
        this.tempMarker.getLatLng().lat,
      ]);
      const linestringg = turfHelpers.lineString(
        this.pigRun?.pigPath.pigLineGenerated
      );
      var snapped = turf.nearestPointOnLine(linestringg, pt, {
        units: 'meters',
      });
      if (
        snapped.geometry?.coordinates[0] &&
        snapped.geometry?.coordinates[1]
      ) {
        this.GetnextMarker(
          snapped.geometry?.coordinates[1],
          snapped.geometry?.coordinates[0]
        );

        this.tempMarker = L.marker(
          L.latLng(
            snapped.geometry?.coordinates[1],
            snapped.geometry?.coordinates[0]
          ),
          { draggable: false, icon: PigRunCommon.pigIcon_2, zIndexOffset: -5 }
        );
        this.tempMarker.addTo(this.map);

        this.tempMarker_lat = this.tempMarker.getLatLng().lat;
        this.tempMarker_lon = this.tempMarker.getLatLng().lng;
        this.EditPigDialog = true;
        if (this.pigRun.calculatedSpeed) {
          this.tempMarker_Speed = this.pigRun.calculatedSpeed * 2.23694;
          this.tempMarker_Speed = this.tempMarker_Speed * 100;
          this.tempMarker_Speed = Math.round(this.tempMarker_Speed) / 100;
        } else {
          this.tempMarker_Speed = this.pigRun.pigPath.expectedSpeed * 2.23694;
          this.tempMarker_Speed = this.tempMarker_Speed * 100;
          this.tempMarker_Speed = Math.round(this.tempMarker_Speed) / 100;
        }
      }
    }
  }

  public onCancelEditSpeed() {
    if (this.tempMarker != null) {
      this.tempMarker?.onRemove(this.map);
      this.tempMarker = null;
    }
  }
  public onEditRunSpeed() {
    this.tempMarker?.onRemove(this.map);

    // Speed info contained in tempMarker_Speed
    //tempMarker_Speed
    //this.tempMarker_lat
    //this.tempMarker_lon
    //this.next_index
    //this.remaining_distance_toNext
    // We also need to know UTC time of when Pig icon was Edited
    // We need to edit the expected speed in PigRun to be tempmarker_Speed
    if (this.pigRun) {
      this.pigRun.PigEditTime = new Date(); // we are going to get this directly from server

      this.pigRun.EditNextMarkerIndex = this.next_index;
      this.pigRun.EditNextMarkerIndex2 = this.next_index2;

      this.pigRun.EditPigLocLat = this.tempMarker_lat;
      this.pigRun.EditPigLocLon = this.tempMarker_lon;
      if (this.pigMarker && this.pigRun.IsEdit == false) {
        var current_latlon = this.pigMarker.getLatLng();
        this.pigRun.currentPigLocationLat = current_latlon.lat;
        this.pigRun.currentPigLocationLon = current_latlon.lng;
      }
      this.pigRun.IsEdit = true;
      this.pigRun.Phase = 1;
      if (this.tempMarker_Speed) {
        this.pigRun.NewSpeed = this.tempMarker_Speed * 0.44704;
      }
      this.tempMarker = null;
      this.$store.dispatch('upsertPigRun', this.pigRun);
    }
  }

  public newMarker_lat = 0;
  public newMarker_lon = 0;
  public addMarkerDialog = false;
  public tempAGMMarker: L.Marker | null = null;
  public newMarkerTip = '';
  public newMarkerName = '';
  public onAddAGMMarker() {
    this.map.on('click', (e: any) => {
      this.newMarker_lat = e.latlng.lat;
      this.newMarker_lon = e.latlng.lng;
      this.newMarkerTip = this.newMarker_lat + ',' + this.newMarker_lon;
      this.addMarkerDialog = true;
      this.map.off('click');

      this.tempAGMMarker = L.marker(
        L.latLng(this.newMarker_lat, this.newMarker_lon),
        { draggable: true, icon: PigRunCommon.trackIcon_3, zIndexOffset: -5 }
      ).bindTooltip(this.newMarkerTip);
      this.tempAGMMarker.on('dragend', () => {
        if (this.tempAGMMarker && this.tempAGMMarker.dragging) {
          //this.tempAGMMarker.dragging.disable();
          //this.tempAGMMarker.remove();
          this.newMarker_lat = this.tempAGMMarker.getLatLng().lat;
          this.newMarker_lon = this.tempAGMMarker.getLatLng().lng;
          this.newMarkerTip = this.newMarker_lat + ',' + this.newMarker_lon;
          this.tempAGMMarker.setTooltipContent(this.newMarkerTip);
        }
      });

      this.tempAGMMarker.addTo(this.map);

      // Need to figure out this lat/lon is in what order in marker locations
    });
  }

  public onCloseNewMarkerSetup() {
    this.tempAGMMarker?.removeFrom(this.map);
  }

  // onSaveNewMarker.
  //
  // This method is the @click event handler implementation for the addMarkerDialog.
  public onSaveNewMarker() {
    // Remove new temporary marker icon from the map.
    this.tempAGMMarker?.removeFrom(this.map);

    // Check if the name of the dynamic marker has been specified and is unique.
    if (this.CheckNewMarkerName()) {
      // September 17, 2024.
      //
      // Commented out the previous implementation below.
      // this.FigureMarkerStuff();
      this.CreateDynamicMarker();
    }

    this.newMarkerName = '';
  }

  // CreateDynamicMarker.
  //
  // Method used to create a dynamic marker.
  public CreateDynamicMarker() {
    console.log('CreateDynamicMarker. Enter method.');

    // If the following optional properties have been defined.
    if (this.pigRun && this.pigRun.markers && this.pigRun.pigPath.markers) {
      // Construct a Point object for the dynamic marker location.
      //
      // Note: The dynamic marker name is stored in this.newMarkerName.
      const dynamicMarkerPoint = turf.point([
        this.newMarker_lon,
        this.newMarker_lat,
      ]);

      // Construct a LineString object for the existing pig run markers, including any previously created dynamic markers. This
      // will require referencing the pigRun.markers array vs. the pigRun.pigPath.pigLineGenerated array.
      //
      // Create a two-dimensional array of positions.
      var existingMarkersArray = [];

      for (var i = 0; i < this.pigRun.markers.length; i++) {
        existingMarkersArray[i] = [
          this.pigRun.markers[i].lon,
          this.pigRun.markers[i].lat,
        ];
      }

      // Push the launch location to the start of the existingMarkersArray.
      existingMarkersArray.unshift([
        this.pigRun.pigPath.launchSiteLon,
        this.pigRun.pigPath.launchSiteLat,
      ]);

      // Push the receive location onto the end of the existingMarkersArray.
      existingMarkersArray.push([
        this.pigRun.pigPath.recieveSiteLon,
        this.pigRun.pigPath.recieveSiteLat,
      ]);

      // The existingMarkersArray contains the coordinates for the launch location, markers (static and dynamic), and receive location. Next,
      // construct a LineString based on the existingMarkersArray.
      const existingMarkersLineString =
        turfHelpers.lineString(existingMarkersArray);

      // Construct a Point object cooresponding to the launch location.
      const launchLocationPoint = turf.point([
        this.pigRun.pigPath.launchSiteLon,
        this.pigRun.pigPath.launchSiteLat,
      ]);

      // At this point get the Point object residing on the existing LineString closest to the dynamic marker location.
      const dynamicMarkerPointProjectedOntoExistingLineString =
        turf.nearestPointOnLine(existingMarkersLineString, dynamicMarkerPoint, {
          units: 'meters',
        });

      // Construct a new LineString beginning at the launch location to the projected marker point location.
      const launchLocationToProjectedDynamicMarkerLocationLineString =
        turf.lineSlice(
          launchLocationPoint,
          dynamicMarkerPointProjectedOntoExistingLineString,
          existingMarkersLineString
        );

      // Get the distance from the launch location to the projected dynamic marker location in meters.
      const distanceToProjectedDynamicMarkerLocationFromLaunchLocationInMeters =
        turf.lineDistance(
          launchLocationToProjectedDynamicMarkerLocationLineString,
          { units: 'meters' }
        );

      console.log('CreateDynamicMarker. Before logging distance.');
      console.log(
        distanceToProjectedDynamicMarkerLocationFromLaunchLocationInMeters
      );

      // console.log('CreateDynamicMarker. Before logging markers');
      // console.log(this.pigRun.markers);

      // The next step is to determine the correct location of where to place the dynamic marker in the pigRun.markers array.
      //
      // Note: Each element of the pigRun.markers array is of type AgmMarker and excludes the launch and receive location. Refer to the json data in the PigRun.Markers column.
      let markerIndexLocationForDynamicMarker = -1;

      for (var i = 0; i < this.pigRun.markers.length; i++) {
        const marker = this.pigRun.markers[i];

        if (marker.DistanceFromLaunch != undefined) {
          if (
            marker.DistanceFromLaunch <=
            distanceToProjectedDynamicMarkerLocationFromLaunchLocationInMeters
          ) {
            markerIndexLocationForDynamicMarker = i + 1;

            continue;
          }

          break;
        }
      }

      if (markerIndexLocationForDynamicMarker == -1) {
        markerIndexLocationForDynamicMarker = 0;
      }

      // console.log('CreateDynamicMarker. Dynamic marker index data.');
      // console.log('distanceToProjectedDynamicMarkerLocationFromLaunchLocationInMeters = [' + distanceToProjectedDynamicMarkerLocationFromLaunchLocationInMeters + ']');
      // console.log('markerIndexLocationForDynamicMarker = [' + markerIndexLocationForDynamicMarker + ']');

      // Construct an AgmMarker object for the dynamic marker.
      let dynamicAgmMarker: AgmMarker = new AgmMarker(
        this.newMarkerName,
        this.newMarker_lat,
        this.newMarker_lon,
        'TRACK',
        undefined,
        undefined,
        'AGM',
        undefined,
        true,
        true,
        undefined,
        undefined,
        distanceToProjectedDynamicMarkerLocationFromLaunchLocationInMeters
      );

      // Splice the AgmMarker object into the pigRun.markers array.
      this.pigRun.markers.splice(
        markerIndexLocationForDynamicMarker,
        0,
        dynamicAgmMarker
      );

      // The existingMarkersArray contains the coordinates for the launch location, static markers, previously created dynamic markers, and receive location. Using the spread operator, clone the existingMarkers array, and then splice in the
      // coordinates of the new dynamic marker.
      const staticAndDynamicMarkersWithLaunchAndReceiveLocationArray = [
        ...existingMarkersArray,
      ];

      if (
        dynamicMarkerPointProjectedOntoExistingLineString.geometry
          ?.coordinates[0] != undefined &&
        dynamicMarkerPointProjectedOntoExistingLineString.geometry
          .coordinates[1] != undefined
      ) {
        staticAndDynamicMarkersWithLaunchAndReceiveLocationArray.splice(
          markerIndexLocationForDynamicMarker + 1,
          0,
          [
            dynamicMarkerPointProjectedOntoExistingLineString.geometry
              .coordinates[0],
            dynamicMarkerPointProjectedOntoExistingLineString.geometry
              .coordinates[1],
          ]
        );
      }

      // console.log('CreateDynamicMarker. Before logging updated pigRun.markers array.');
      console.log(this.pigRun.markers);

      console.log(
        'CreateDynamicMarker. Before logging dynamicMarkerPointProjectedOntoExistingLineString'
      );
      console.log(dynamicMarkerPointProjectedOntoExistingLineString);

      console.log(
        'CreateDynamicMarker. Before logging staticAndDynamicMarkersWithLaunchAndReceiveLocationArray'
      );
      console.log(staticAndDynamicMarkersWithLaunchAndReceiveLocationArray);

      // The staticAndDynamicMarkersWithLaunchAndReceiveLocationArray contains the coordinates for the launch location, markers (static and the new dynamic), and receive location. Next,
      // construct a LineString based on the staticAndDynamicMarkersWithLaunchAndReceiveLocationArray.
      const updatedMarkersIncludingStaticAndDynamicMarkersLineString =
        turfHelpers.lineString(
          staticAndDynamicMarkersWithLaunchAndReceiveLocationArray
        );

      // Iterate the pigRun.markers array. Access the coordinates for each AgmMarker object to re-compute the distance from launch value. Note that the pigRun.markers array excludes
      // the launch and receive location.
      for (var i = 0; i < this.pigRun.markers.length; i++) {
        const marker = this.pigRun.markers[i];

        // Construct a Point object for the marker.
        const markerPoint = turf.point([marker.lon, marker.lat]);

        // Construct a LineString object from the launch location to the current marker.
        const launchLocationToMarkerLocationLineString = turf.lineSlice(
          launchLocationPoint,
          markerPoint,
          updatedMarkersIncludingStaticAndDynamicMarkersLineString
        );

        // Get the distance from the launch location to the marker location in meters.
        const distanceFromLaunchLocationToMarkerLocationInMeters =
          turf.lineDistance(launchLocationToMarkerLocationLineString, {
            units: 'meters',
          });

        // Update the DistanceFromLaunch value for the marker.
        marker.DistanceFromLaunch =
          distanceFromLaunchLocationToMarkerLocationInMeters;

        console.log(
          'CreateDynamicMarker. Marker name = [' +
            marker.name +
            ']. DistanceToLaunch = [' +
            distanceFromLaunchLocationToMarkerLocationInMeters +
            ']'
        );
      }

      // The next step is to update the PigRun.Markers column.
      //
      // Note: This code pattern was copied over from the previous implementation.
      const latLngs: L.LatLng[] = [];

      // Launch location.
      latLngs.push(
        L.latLng(
          this.pigRun.pigPath.launchSiteLat,
          this.pigRun.pigPath.launchSiteLon
        )
      );

      // For each marker.
      this.pigRun.markers.forEach((agmMarker: AgmMarker) => {
        latLngs.push(L.latLng(agmMarker.lat, agmMarker.lon));
      });

      // Receive location.
      latLngs.push(
        L.latLng(
          this.pigRun.pigPath.recieveSiteLat,
          this.pigRun.pigPath.recieveSiteLon
        )
      );

      var pigRunOverlayGenerated: L.Polyline;
      pigRunOverlayGenerated = L.polyline(latLngs, PigRunCommon.pigRunOptions);

      this.pigRun.pigPath.pigLineGenerated = pigRunOverlayGenerated.toGeoJSON()
        .geometry.coordinates as number[][];

      // Upsert the pig run.
      this.$store.dispatch('upsertPigRun', this.pigRun);

      // DO NOT DELETE THE FOLLOWING SNIPPET - update PigRun.Markers column.
      //
      // const latLngs: L.LatLng[] = [];
      //
      // if (this.pigRun && this.pigRun.markers && this.pigRun.pigPath.markers)
      // {
      //     // Launch location.
      //     latLngs.push(L.latLng(this.pigRun.pigPath.launchSiteLat, this.pigRun.pigPath.launchSiteLon));
      //
      //     // Each marker.
      //     this.pigRun.markers?.forEach((d: AgmMarker) =>
      //     {
      //         latLngs.push(L.latLng(d.lat, d.lon));
      //     });
      //
      //     // Receive location.
      //     latLngs.push(L.latLng(this.pigRun.pigPath.recieveSiteLat, this.pigRun.pigPath.recieveSiteLon));
      //
      //     var pigRunOverlayGenerated: L.Polyline | null = null;
      //
      //     pigRunOverlayGenerated = L.polyline(latLngs, PigRunCommon.pigRunOptions);
      //
      //     this.pigRun.pigPath.pigLineGenerated = pigRunOverlayGenerated.toGeoJSON().geometry.coordinates as number[][];
      //
      //     // Reference code for updating the PigRun.Marker data below.
      //     // console.log('CreateDynamicMarker. Before logging pigRun.markers');
      //     // console.log(this.pigRun.markers);
      //     //
      //     // console.log('CreateDynamicMarker. Before setting pigRun.markers[13].DistanceFromLaunch');
      //
      //     // Note: The correct markers to update are pigRun.markers not pigRun.pigPath.markers.
      //     //
      //     // Note: The following update will execute correct when followed by dispatching 'upsertPigRun'.
      //     // this.pigRun.markers[13].DistanceFromLaunch = 3052.555;
      //
      //     this.$store.dispatch('upsertPigRun', this.pigRun);
      // }
    } // if (this.pigRun && this.pigRun.markers && this.pigRun.pigPath.markers)

    console.log('CreateDynamicMarker. Exit method.');
  }

  // FigureMarkerStuff.
  //
  // September 17, 2024.
  //
  // Commenting out the previous implementation and left here for reference. Replaced by the CreateDynamicMarker method.
  // public FigureMarkerStuff()
  // {
  //     if(this.pigRun && this.pigRun.pigPath.pigLineGenerated && this.pigRun.markers)
  //     {
  //         const latLngs: L.LatLng[] = [];
  //         const newMarkerloc = turf.point([this.newMarker_lon, this.newMarker_lat]);
  //
  //         if(this.pigRun && this.pigRun.pigPath.pigLineGenerated && this.pigRun.markers)
  //         {
  //             var pt = turf.point([this.newMarker_lon, this.newMarker_lat]);
  //             const linestringg = turfHelpers.lineString(this.pigRun?.pigPath.pigLineGenerated);
  //             var snapped = turf.nearestPointOnLine(linestringg, pt, {units: 'meters'});
  //             if(snapped.geometry?.coordinates[0] && snapped.geometry?.coordinates[1])
  //             {
  //                 var Marker_nextIndex = this.GetnextMarkerV2(snapped.geometry?.coordinates[1],snapped.geometry?.coordinates[0])
  //                 if(Marker_nextIndex >-1)
  //                 {
  //                     // index does exist
  //                     if(Marker_nextIndex == 0)
  //                     {
  //                         // previous is launch
  //                         const launchloc = turf.point([this.pigRun.pigPath.launchSiteLon, this.pigRun.pigPath.launchSiteLat]);
  //                         const DistanceFromLaunch = turf.distance(launchloc, newMarkerloc, {units: 'meters'});
  //                         var new_marker:AgmMarker = new AgmMarker(this.newMarkerName,this.newMarker_lat,this.newMarker_lon,'TRACK',undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined,DistanceFromLaunch);
  //                         this.pigRun.markers.splice(0, 0, new_marker);
  //                     }
  //                     else
  //                     {
  //                         const markerLocation = turf.point([this.pigRun.markers[Marker_nextIndex - 1].lon, this.pigRun.markers[Marker_nextIndex - 1].lat]);
  //                         const distance = turf.distance(markerLocation, newMarkerloc, {units: 'meters'});
  //                         const a = this.pigRun.markers[Marker_nextIndex - 1].DistanceFromLaunch as number;
  //                         var DistanceFromLaunch = a + distance ;
  //                         var new_marker:AgmMarker = new AgmMarker(this.newMarkerName,this.newMarker_lat,this.newMarker_lon,'TRACK',undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined,DistanceFromLaunch);
  //                         this.pigRun.markers.splice(Marker_nextIndex, 0, new_marker);
  //                     }
  //                 }
  //                 else
  //                 {
  //                     // next is receive
  //                     const receiveloc = turf.point([this.pigRun.pigPath.recieveSiteLon, this.pigRun.pigPath.recieveSiteLat]);
  //                     const DistanceFromLaunch = turf.distance(receiveloc, newMarkerloc, {units: 'meters'});
  //                     var new_marker:AgmMarker = new AgmMarker(this.newMarkerName,this.newMarker_lat,this.newMarker_lon,'TRACK',undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined,DistanceFromLaunch);
  //                     this.pigRun.markers.push(new_marker);
  //                 }
  //                 latLngs.push(L.latLng(this.pigRun.pigPath.launchSiteLat, this.pigRun.pigPath.launchSiteLon))
  //                 this.pigRun.markers.forEach((d: AgmMarker) => {
  //                     latLngs.push(L.latLng(d.lat, d.lon))
  //                 });
  //
  //                 latLngs.push(L.latLng(this.pigRun.pigPath.recieveSiteLat, this.pigRun.pigPath.recieveSiteLon))
  //
  //                 var pigRunOverlayGenerated: L.Polyline | null = null;
  //                 pigRunOverlayGenerated = L.polyline(latLngs, PigRunCommon.pigRunOptions);
  //                 this.pigRun.pigPath.pigLineGenerated = pigRunOverlayGenerated.toGeoJSON().geometry.coordinates as number[][];
  //                 //this.pigRun is ready now to be upserted
  //                 this.$store.dispatch('upsertPigRun', this.pigRun);
  //             }
  //         }
  //     }
  // }

  // CheckNewMarkerName.
  //
  // Method used to verify the marker name specified, when creating a dynamic marker, is unique and not an empty string.
  public CheckNewMarkerName() {
    // If the specified marker name is an empty string, return false.
    if (this.newMarkerName == '') {
      return false;
    }

    // Verify the specified marker name is unique.
    if (
      this.pigRun != null &&
      this.pigRun != undefined &&
      this.pigRun.markers
    ) {
      var idx = this.pigRun.markers.findIndex(
        (marker: AgmMarker) => marker.name == this.newMarkerName
      );

      // If the specified marker name was not found, return true.
      if (idx < 0) {
        return true;
      }
    }

    return false;
  }
}
